import { Divider, Grid } from "@mui/material";
import React from "react";

const SpecTableComponent = ({ specifications }) => {
  return (
    <Grid container>
      {specifications.map((specificationGroup) => {
        return (
          <Grid item container xs={12}>
            <Grid item xs={12} display={"flex"} flexDirection={"column"}>
              <h5 style={{ textAlign: "right" }}>{specificationGroup.title}</h5>
              <Divider sx={{ opacity: "0.5",height:'0.1px' }} />
              {specificationGroup.specifications.map((specification, index) => {
                return (
                  <>
                    <Grid
                      item
                      container
                      xs={12}
                      style={{
                        backgroundColor: index % 2 == 0 && "#28a5df",
                        padding: "1rem",
                      }}
                    >
                      <Grid item xs={6}>
                        {specification.label}
                      </Grid>
                      <Grid item xs={6} style={{ textAlign: "right" }}>
                        {specification.value}
                      </Grid>
                    </Grid>
                  </>
                );
              })}
              {specificationGroup.remarks.map((remark) => (
                <span style={{ textAlign: "center",color:'slategray' }}>{remark}</span>
              ))}
            </Grid>
          </Grid>
        );
      })}
    </Grid>
    // <div className="container">
    //   <div className="table-wrapper">
    //     <div>
    //       <h5 style={{ textAlign: "left" }}>Specifications</h5>
    //       <hr style={{ marginBottom: "6px" }} />
    //       {specifications.map((specification) => {
    //         return (
    //           <table className="sc-table">
    //             <tbody>

    //               {specification.remarks.map((remark) => {
    //                 return <p className="caption">{remark}</p>;
    //               })}
    //             </tbody>
    //           </table>
    //         );
    //       })}
    //     </div>
    //   </div>
    // </div>
  );
};

export default SpecTableComponent;
