import React, {
  useCallback,
  useEffect,
  useReducer,
  useRef,
  useState,
} from "react";
import ProductsDesc from "../../components/productsDesc/ProductsDesc";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  Drawer,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import { url } from "../../constants";
import axios from "axios";
import { Link, Navigate, useParams } from "react-router-dom";
import ProductHeader from "../productheader";
import { ProductItemThumbnailComponent } from "./productitemthumbnail.component";
import {
  Filter1Outlined,
  FilterList,
  SearchOutlined,
} from "@mui/icons-material";
import * as _ from "lodash";
import { filterHelper, filterHelperV2 } from "./products.service";
import { NoProductComponent } from "./noproduct.component";
import { Helmet } from "react-helmet";
import { useSetHeaderContext } from "../root/header/header.context";
import { FiltersComponent } from "./filters.component";
import { MobileFilterComponent } from "./mobilefilter.component";
import {
  useFilterContext,
  useResetAllContext,
  useSetFilterContext,
  useSetSearchContext,
} from "./filters.context";
import { useOpenDrawerDispatch } from "../root/drawer/drawer.context";

const Products = () => {
  const setHeader = useSetHeaderContext();
  const [products, setProducts] = useState([]);
  const [viewProducts, setViewProducts] = useState([]);
  const [filters, setFilters] = useState([]);
  const [brands, setBrands] = useState([]);

  const setCheckedFilters = useSetFilterContext();
  const checkedFilters = useFilterContext();
  const setSearch = useSetSearchContext();
  const resetAllFilter = useResetAllContext();
  const [resultCount, setResultCount] = useState(0);

  const [noProductFound, setNoProductFound] = useState(false);
  const openDrawer = useOpenDrawerDispatch();
  const searchDebounce = useCallback(
    _.debounce((value) => setSearch(value), 300),
    []
  );

  const filterComponents = [
    {
      items: products,
      filterKey: "product",
      itemKey: "name",
      title: "Product Types",
    },
    {
      items: brands,
      filterKey: "brand",
      title: "Brands",
    },
    {
      items: filters,
      filterKey: "filter",
      title: "Filters",
    },
  ];

  function handleFilter(checked, value, key) {
    setCheckedFilters(value, key);
  }

  function handleFilterChange() {
    setNoProductFound(false);
    const filteredProducts = filterHelperV2(checkedFilters, products);

    if (filteredProducts.length == 0) {
      if (
        checkedFilters.brand.length == 0 &&
        checkedFilters.filter.length == 0 &&
        checkedFilters.product.length == 0 &&
        checkedFilters.search.length == 0
      ) {
        setResultCount(0);

        setViewProducts(products);
      } else {
        setNoProductFound(true);
      }
    } else {
      setResultCount(
        filteredProducts.flatMap((product) => product.productItems).length
      );
      setViewProducts(filteredProducts);
    }
  }

  useEffect(() => {
    resetAllFilter();
    setHeader({
      bannerImage: "/assets/header-services.jpg",
      description: "Range of products incorporated with our solutions.",
      title: "Products",
    });
    axios.get(`${url}/api/v1/cms/products?items=true`).then((response) => {
      setProducts(response.data);
      setViewProducts(response.data);
    });
    axios.get(`${url}/api/v1/cms/products/brands`).then((response) => {
      setBrands(response.data);
    });
    axios.get(`${url}/api/v1/cms/products/filters`).then((response) => {
      setFilters(response.data);
    });
  }, []);

  useEffect(() => {
    handleFilterChange();
  }, [checkedFilters]);

  return (
    <div style={{ marginBottom: "5rem" }}>
      <Helmet>
        <title>Products</title>
        <meta
          name="description"
          content="Range of products incorporated with our solutions."
        />
      </Helmet>
      <Grid
        container
        style={{
          // flexDirection: "column",
          width:
            window.visualViewport.width < 1080
              ? "100vw"
              : window.visualViewport.width < 1600
              ? "85vw"
              : "75vw",
          // alignItems: "center",
          margin: "auto",
        }}
        spacing={2}
      >
        {window.visualViewport.width > 1200 ? (
          <Grid
            item
            xs={3}
            container
            rowGap={2}
            style={{
              height: "min-content",
              padding: "1rem",
            }}
          >
            <Grid item xs={12}>
              <TextField
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchOutlined />
                    </InputAdornment>
                  ),
                }}
                placeholder="Search"
                size="small"
                onChange={(e) => searchDebounce(e.target.value)}
              />
            </Grid>
            <FiltersComponent
              filters={filterComponents}
              checkedFilters={checkedFilters}
              handleFilter={handleFilter}
            />
          </Grid>
        ) : (
          <Grid item container xs={12} alignItems={"center"}>
            <Grid item xs={10}>
              <TextField
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchOutlined />
                    </InputAdornment>
                  ),
                }}
                placeholder="Search"
                size="small"
                onChange={(e) => searchDebounce(e.target.value)}
              />
            </Grid>
            <Grid item xs={2} display={"flex"} justifyContent={"center"}>
              <IconButton
                onClick={() =>
                  openDrawer({
                    title: (
                      <div style={{ flex: "1", display: "flex" }}>
                        <h5
                          style={{
                            padding: "1rem",
                            flex: "1",
                          }}
                        >
                          Filters
                        </h5>
                      </div>
                    ),
                    content: (
                      <MobileFilterComponent
                        filters={filterComponents}
                        handleFilterChange={handleFilterChange}
                        products={products}
                        resultCount={resultCount}
                      />
                    ),
                    fullHeight: true,
                    width: "100vw",
                  })
                }
              >
                <FilterList />
              </IconButton>
            </Grid>
          </Grid>
        )}
        <Grid
          item
          container
          xs={12}
          md={9}
          style={{ height: "min-content" }}
          rowGap={2}
        >
          <Grid item container xs={12} columnGap={2}></Grid>
          {noProductFound ? (
            <NoProductComponent />
          ) : (
            viewProducts.map((product) => {
              return (
                product.productItems?.length > 0 && (
                  <Grid container direction={"column"} rowGap={2}>
                    <Link
                      to={`/products/${product.dir_name}`}
                      style={{ textDecoration: "none", color: "black" }}
                    >
                      <h5 style={{ fontStyle: "bold", margin: "0" }}>
                        {product.name}
                      </h5>
                    </Link>
                    <Grid
                      container
                      style={{ maxWidth: "100%", width: "100%" }}
                      spacing={2}
                    >
                      {product.productItems.map((item) => {
                        return (
                          <ProductItemThumbnailComponent
                            link={`/products/${product.dir_name}/${item.dir_name}`}
                            image={item.image}
                            state={item}
                            title={item.name}
                            product={product}
                            productItem={item}
                          />
                        );
                      })}
                    </Grid>
                    <Divider sx={{ opacity: "0.5", height: "0.1px" }} />
                  </Grid>
                )
              );
            })
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default Products;
