import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
} from "@mui/material";
import { useState } from "react";

export function ImageGalleryDialogComponent({ open, images, setImageDialog }) {
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  return (
    <Dialog
      open={open}
      maxWidth="lg"
      fullWidth
      onClose={() => setImageDialog(false)}
    >
      <DialogTitle>Product Gallery</DialogTitle>
      <DialogContent>
        <Grid container rowGap={2}>
          <Grid
            item
            xs={12}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              position: "relative",
              height: "500px",
            }}
          >
            <img
              src={images[selectedImageIndex]}
              style={{ maxWidth: "100%", maxHeight: "100%" }}
            />
          </Grid>
          <Grid item xs={12}>
            <Divider sx={{ opacity: "0.5",height:'0.1px' }} />
          </Grid>
          <Grid
            item
            container
            xs={12}
            columnGap={2}
            direction={"row"}
            style={{
              //   display: "flex",
              // maxWidth: "100%",
              overflow: "auto",
              height: "150px",
              flexWrap: "nowrap",
            }}
          >
            {images.map((image, index) => (
              <>
                <div
                  style={{
                    // flex: "1",
                    width: "100px",
                    flexShrink: "0",
                    height: "100px",
                    alignItems: "center",
                    justifyContent: "center",
                    display: "flex",
                    border: `solid 1px ${
                      index == selectedImageIndex
                        ? "rgb(0,155,155)"
                        : "rgb(0,0,0,0.2)"
                    }`,
                    padding: ".5rem",
                    borderRadius: ".5rem",
                    cursor: "pointer",
                  }}
                  onClick={() => setSelectedImageIndex(index)}
                >
                  <img
                    src={images[index]}
                    style={{ maxWidth: "100%", maxHeight: "100%" }}
                  />
                </div>
              </>
            ))}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          className="submit-btn"
          onClick={() => setImageDialog(false)}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
