export function getScrollbarWidth() {
    // Create a temporary div container and append it into the body
    const container = document.createElement('div');
    // Append the container into the body
    document.body.appendChild(container);

    // Force scrollbars on the container
    container.style.overflow = 'scroll';

    // Add a temporary div inside the container
    const inner = document.createElement('div');
    container.appendChild(inner);

    // Calculate the width of the scrollbar
    const scrollbarWidth = container.offsetWidth - inner.offsetWidth;

    // Remove the temporary elements
    document.body.removeChild(container);

    return scrollbarWidth;
}