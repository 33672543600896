import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router";
import { url } from "../../constants";
import axios from "axios";
import { FeatureComponent } from "../features.component";
import { Divider, Grid } from "@mui/material";
import { ProductItemThumbnailComponent } from "../products/productitemthumbnail.component";
import { ImageGalleryComponent } from "../../components/imagegallery/imagegallery.component";
import { ImageGalleryDialogComponent } from "../../components/imagegallery/imagegallerydialog.component";
import { Helmet } from "react-helmet";
import { useSetHeaderContext } from "../root/header/header.context";

export function SolutionComponent({}) {
  const { solutionName } = useParams();
  const [solution, setSolution] = useState();
  const [imageDialog, setImageDialog] = useState(false);
  const setHeader = useSetHeaderContext();

  useEffect(() => {
    axios
      .get(`${url}/api/v1/cms/solutions/${solutionName}?q=dir_name`)
      .then((response) => {
        const solution = response.data;
        setSolution(solution);
        setHeader({
          bannerImage: solution.banner_image,
          description: solution.brief_description,
          title: solution.name,
          button: solution.brochure && (
            <a href={solution.brochure} target="_blank" download>
              <button className="learn-more">Download Brochure</button>
            </a>
          ),
        });
      });
  }, []);
  return solution ? (
    <div style={{ marginBottom: "5rem" }}>
      <Helmet>
        <title>{solution.name}</title>
        <meta name="description" content={solution.brief_description} />
      </Helmet>
      <Grid
        container
        style={{
          width:
            window.visualViewport.width < 1080
              ? "100vw"
              : window.visualViewport.width < 1600
              ? "85vw"
              : "75vw",
          margin: "auto",
          justifyContent: "center",
          padding: "1rem",
          // alignItems: "center",
        }}
        rowGap={2}
        columnSpacing={window.visualViewport.width < 1080 ? 0 : 2}
      >
        <Grid item container xs={12} columnSpacing={2} height={"max-content"}>
          <Grid
            item
            container
            xs={12}
            md={6}
            height={"100%"}
            style={{
              border: "solid 1px rgb(0,0,0,0.2)",
              borderRadius: "1rem",
              padding: "2rem",
            }}
            rowGap={4}
          >
            <ImageGalleryComponent
              setImageDialog={setImageDialog}
              images={
                solution.additional_image
                  ? [solution.image, ...JSON.parse(solution.additional_image)]
                  : [solution.image]
              }
            />
          </Grid>
          <Grid item container xs={12} md={6} rowGap={2} height={"100%"}>
            <Grid item xs={12}>
              <p>{solution.description}</p>
            </Grid>
            <Grid item xs={12}>
              <Divider sx={{ opacity: "0.5", height: "0.1px" }} />
            </Grid>
            <Grid item xs={12}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                {JSON.parse(solution.features).length > 0 && (
                  <FeatureComponent features={JSON.parse(solution.features)} />
                )}
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Divider sx={{ opacity: "0.5", height: "0.1px" }} />
        </Grid>

        {solution.related_products.length > 0 && (
          <Grid
            item
            container
            xs={12}
            style={{ justifyContent: "center" }}
            spacing={2}
          >
            <Grid item xs={12} style={{ textAlign: "center" }}>
              <h4>Related Products</h4>
            </Grid>
            {solution.related_products.map((product) => {
              return (
                product.details && (
                  <>
                    <ProductItemThumbnailComponent
                      image={product.details.product_image}
                      title={product.details.name}
                      link={`/products/${product.details.dir_name}`}
                      itemCount={6}
                    />
                  </>
                )
              );
            })}
          </Grid>
        )}
      </Grid>
      {imageDialog && (
        <ImageGalleryDialogComponent
          setImageDialog={setImageDialog}
          open={imageDialog}
          images={
            solution.additional_image
              ? [solution.image, ...JSON.parse(solution.additional_image)]
              : [solution.image]
          }
        />
      )}
    </div>
  ) : (
    <></>
  );
}
