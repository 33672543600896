import React, { useEffect, useState } from "react";
import HomeStandout from "./HomeStandout";
import HomeRepair from "./HomeRepair";
import "./home.style.css";

const Home = () => {
  return (
    <div>
      <HomeStandout />
      <HomeRepair />
      {/* <Enquiry /> */}
    </div>
  );
};

export default Home;
