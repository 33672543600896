export function DotDecoComponent() {
  return (
    <svg
      width="1645"
      height="70"
      viewBox="0 0 1645 70"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="dot-pattern"
      style={{ width: "100%" }}
    >
      <g opacity="0.15">
        <path
          d="M36.5308 2.5C36.5308 3.88072 35.4114 5 34.0308 5C32.6501 5 31.5308 3.88072 31.5308 2.5C31.5308 1.11929 32.6501 0 34.0308 0C35.4114 0 36.5308 1.11929 36.5308 2.5Z"
          fill="#EFEFEF"
        />
        <path
          d="M68.0615 2.5C68.0615 3.88072 66.9422 5 65.5615 5C64.1809 5 63.0615 3.88072 63.0615 2.5C63.0615 1.11929 64.1809 0 65.5615 0C66.9422 0 68.0615 1.11929 68.0615 2.5Z"
          fill="#EFEFEF"
        />
        <path
          d="M99.5928 2.5C99.5928 3.88072 98.4734 5 97.0928 5C95.7121 5 94.5928 3.88072 94.5928 2.5C94.5928 1.11929 95.7121 0 97.0928 0C98.4734 0 99.5928 1.11929 99.5928 2.5Z"
          fill="#EFEFEF"
        />
        <path
          d="M131.124 2.5C131.124 3.88072 130.004 5 128.624 5C127.243 5 126.124 3.88072 126.124 2.5C126.124 1.11929 127.243 0 128.624 0C130.004 0 131.124 1.11929 131.124 2.5Z"
          fill="#EFEFEF"
        />
        <path
          d="M162.654 2.5C162.654 3.88072 161.534 5 160.154 5C158.774 5 157.654 3.88072 157.654 2.5C157.654 1.11929 158.774 0 160.154 0C161.534 0 162.654 1.11929 162.654 2.5Z"
          fill="#EFEFEF"
        />
        <path
          d="M194.185 2.5C194.185 3.88072 193.065 5 191.685 5C190.305 5 189.185 3.88072 189.185 2.5C189.185 1.11929 190.305 0 191.685 0C193.065 0 194.185 1.11929 194.185 2.5Z"
          fill="#EFEFEF"
        />
        <path
          d="M225.716 2.5C225.716 3.88072 224.596 5 223.216 5C221.836 5 220.716 3.88072 220.716 2.5C220.716 1.11929 221.836 0 223.216 0C224.596 0 225.716 1.11929 225.716 2.5Z"
          fill="#EFEFEF"
        />
        <path
          d="M257.247 2.5C257.247 3.88072 256.127 5 254.747 5C253.367 5 252.247 3.88072 252.247 2.5C252.247 1.11929 253.367 0 254.747 0C256.127 0 257.247 1.11929 257.247 2.5Z"
          fill="#EFEFEF"
        />
        <path
          d="M288.777 2.5C288.777 3.88072 287.657 5 286.277 5C284.897 5 283.777 3.88072 283.777 2.5C283.777 1.11929 284.897 0 286.277 0C287.657 0 288.777 1.11929 288.777 2.5Z"
          fill="#EFEFEF"
        />
        <path
          d="M320.309 2.5C320.309 3.88072 319.189 5 317.809 5C316.429 5 315.309 3.88072 315.309 2.5C315.309 1.11929 316.429 0 317.809 0C319.189 0 320.309 1.11929 320.309 2.5Z"
          fill="#EFEFEF"
        />
        <path
          d="M351.839 2.5C351.839 3.88072 350.719 5 349.339 5C347.959 5 346.839 3.88072 346.839 2.5C346.839 1.11929 347.959 0 349.339 0C350.719 0 351.839 1.11929 351.839 2.5Z"
          fill="#EFEFEF"
        />
        <path
          d="M383.37 2.5C383.37 3.88072 382.25 5 380.87 5C379.49 5 378.37 3.88072 378.37 2.5C378.37 1.11929 379.49 0 380.87 0C382.25 0 383.37 1.11929 383.37 2.5Z"
          fill="#EFEFEF"
        />
        <path
          d="M68.0615 34.0308C68.0615 35.4114 66.9422 36.5308 65.5615 36.5308C64.1809 36.5308 63.0615 35.4114 63.0615 34.0308C63.0615 32.6501 64.1809 31.5308 65.5615 31.5308C66.9422 31.5308 68.0615 32.6501 68.0615 34.0308Z"
          fill="#EFEFEF"
        />
        <path
          d="M99.5928 34.0308C99.5928 35.4114 98.4734 36.5308 97.0928 36.5308C95.7121 36.5308 94.5928 35.4114 94.5928 34.0308C94.5928 32.6501 95.7121 31.5308 97.0928 31.5308C98.4734 31.5308 99.5928 32.6501 99.5928 34.0308Z"
          fill="#EFEFEF"
        />
        <path
          d="M131.124 34.0308C131.124 35.4114 130.004 36.5308 128.624 36.5308C127.243 36.5308 126.124 35.4114 126.124 34.0308C126.124 32.6501 127.243 31.5308 128.624 31.5308C130.004 31.5308 131.124 32.6501 131.124 34.0308Z"
          fill="#EFEFEF"
        />
        <path
          d="M162.654 34.0308C162.654 35.4114 161.534 36.5308 160.154 36.5308C158.774 36.5308 157.654 35.4114 157.654 34.0308C157.654 32.6501 158.774 31.5308 160.154 31.5308C161.534 31.5308 162.654 32.6501 162.654 34.0308Z"
          fill="#EFEFEF"
        />
        <path
          d="M194.185 34.0308C194.185 35.4114 193.065 36.5308 191.685 36.5308C190.305 36.5308 189.185 35.4114 189.185 34.0308C189.185 32.6501 190.305 31.5308 191.685 31.5308C193.065 31.5308 194.185 32.6501 194.185 34.0308Z"
          fill="#EFEFEF"
        />
        <path
          d="M225.716 34.0308C225.716 35.4114 224.596 36.5308 223.216 36.5308C221.836 36.5308 220.716 35.4114 220.716 34.0308C220.716 32.6501 221.836 31.5308 223.216 31.5308C224.596 31.5308 225.716 32.6501 225.716 34.0308Z"
          fill="#EFEFEF"
        />
        <path
          d="M257.247 34.0308C257.247 35.4114 256.127 36.5308 254.747 36.5308C253.367 36.5308 252.247 35.4114 252.247 34.0308C252.247 32.6501 253.367 31.5308 254.747 31.5308C256.127 31.5308 257.247 32.6501 257.247 34.0308Z"
          fill="#EFEFEF"
        />
        <path
          d="M288.777 34.0308C288.777 35.4114 287.657 36.5308 286.277 36.5308C284.897 36.5308 283.777 35.4114 283.777 34.0308C283.777 32.6501 284.897 31.5308 286.277 31.5308C287.657 31.5308 288.777 32.6501 288.777 34.0308Z"
          fill="#EFEFEF"
        />
        <path
          d="M320.309 34.0308C320.309 35.4114 319.189 36.5308 317.809 36.5308C316.429 36.5308 315.309 35.4114 315.309 34.0308C315.309 32.6501 316.429 31.5308 317.809 31.5308C319.189 31.5308 320.309 32.6501 320.309 34.0308Z"
          fill="#EFEFEF"
        />
        <path
          d="M351.839 34.0308C351.839 35.4114 350.719 36.5308 349.339 36.5308C347.959 36.5308 346.839 35.4114 346.839 34.0308C346.839 32.6501 347.959 31.5308 349.339 31.5308C350.719 31.5308 351.839 32.6501 351.839 34.0308Z"
          fill="#EFEFEF"
        />
        <path
          d="M383.37 34.0308C383.37 35.4114 382.25 36.5308 380.87 36.5308C379.49 36.5308 378.37 35.4114 378.37 34.0308C378.37 32.6501 379.49 31.5308 380.87 31.5308C382.25 31.5308 383.37 32.6501 383.37 34.0308Z"
          fill="#EFEFEF"
        />
        <path
          d="M99.5928 65.5615C99.5928 66.9422 98.4734 68.0615 97.0928 68.0615C95.7121 68.0615 94.5928 66.9422 94.5928 65.5615C94.5928 64.1809 95.7121 63.0615 97.0928 63.0615C98.4734 63.0615 99.5928 64.1809 99.5928 65.5615Z"
          fill="#EFEFEF"
        />
        <path
          d="M131.124 65.5615C131.124 66.9422 130.004 68.0615 128.624 68.0615C127.243 68.0615 126.124 66.9422 126.124 65.5615C126.124 64.1809 127.243 63.0615 128.624 63.0615C130.004 63.0615 131.124 64.1809 131.124 65.5615Z"
          fill="#EFEFEF"
        />
        <path
          d="M162.654 65.5615C162.654 66.9422 161.534 68.0615 160.154 68.0615C158.774 68.0615 157.654 66.9422 157.654 65.5615C157.654 64.1809 158.774 63.0615 160.154 63.0615C161.534 63.0615 162.654 64.1809 162.654 65.5615Z"
          fill="#EFEFEF"
        />
        <path
          d="M194.185 65.5615C194.185 66.9422 193.065 68.0615 191.685 68.0615C190.305 68.0615 189.185 66.9422 189.185 65.5615C189.185 64.1809 190.305 63.0615 191.685 63.0615C193.065 63.0615 194.185 64.1809 194.185 65.5615Z"
          fill="#EFEFEF"
        />
        <path
          d="M225.716 65.5615C225.716 66.9422 224.596 68.0615 223.216 68.0615C221.836 68.0615 220.716 66.9422 220.716 65.5615C220.716 64.1809 221.836 63.0615 223.216 63.0615C224.596 63.0615 225.716 64.1809 225.716 65.5615Z"
          fill="#EFEFEF"
        />
        <path
          d="M257.247 65.5615C257.247 66.9422 256.127 68.0615 254.747 68.0615C253.367 68.0615 252.247 66.9422 252.247 65.5615C252.247 64.1809 253.367 63.0615 254.747 63.0615C256.127 63.0615 257.247 64.1809 257.247 65.5615Z"
          fill="#EFEFEF"
        />
        <path
          d="M288.777 65.5615C288.777 66.9422 287.657 68.0615 286.277 68.0615C284.897 68.0615 283.777 66.9422 283.777 65.5615C283.777 64.1809 284.897 63.0615 286.277 63.0615C287.657 63.0615 288.777 64.1809 288.777 65.5615Z"
          fill="#EFEFEF"
        />
        <path
          d="M320.309 65.5615C320.309 66.9422 319.189 68.0615 317.809 68.0615C316.429 68.0615 315.309 66.9422 315.309 65.5615C315.309 64.1809 316.429 63.0615 317.809 63.0615C319.189 63.0615 320.309 64.1809 320.309 65.5615Z"
          fill="#EFEFEF"
        />
        <path
          d="M351.839 65.5615C351.839 66.9422 350.719 68.0615 349.339 68.0615C347.959 68.0615 346.839 66.9422 346.839 65.5615C346.839 64.1809 347.959 63.0615 349.339 63.0615C350.719 63.0615 351.839 64.1809 351.839 65.5615Z"
          fill="#EFEFEF"
        />
        <path
          d="M383.37 65.5615C383.37 66.9422 382.25 68.0615 380.87 68.0615C379.49 68.0615 378.37 66.9422 378.37 65.5615C378.37 64.1809 379.49 63.0615 380.87 63.0615C382.25 63.0615 383.37 64.1809 383.37 65.5615Z"
          fill="#EFEFEF"
        />
        <path
          d="M131.124 97.0925C131.124 98.4732 130.004 99.5925 128.624 99.5925C127.243 99.5925 126.124 98.4732 126.124 97.0925C126.124 95.7119 127.243 94.5925 128.624 94.5925C130.004 94.5925 131.124 95.7119 131.124 97.0925Z"
          fill="#EFEFEF"
        />
        <path
          d="M162.654 97.0925C162.654 98.4732 161.534 99.5925 160.154 99.5925C158.774 99.5925 157.654 98.4732 157.654 97.0925C157.654 95.7119 158.774 94.5925 160.154 94.5925C161.534 94.5925 162.654 95.7119 162.654 97.0925Z"
          fill="#EFEFEF"
        />
        <path
          d="M194.185 97.0925C194.185 98.4732 193.065 99.5925 191.685 99.5925C190.305 99.5925 189.185 98.4732 189.185 97.0925C189.185 95.7119 190.305 94.5925 191.685 94.5925C193.065 94.5925 194.185 95.7119 194.185 97.0925Z"
          fill="#EFEFEF"
        />
        <path
          d="M225.716 97.0925C225.716 98.4732 224.596 99.5925 223.216 99.5925C221.836 99.5925 220.716 98.4732 220.716 97.0925C220.716 95.7119 221.836 94.5925 223.216 94.5925C224.596 94.5925 225.716 95.7119 225.716 97.0925Z"
          fill="#EFEFEF"
        />
        <path
          d="M257.247 97.0925C257.247 98.4732 256.127 99.5925 254.747 99.5925C253.367 99.5925 252.247 98.4732 252.247 97.0925C252.247 95.7119 253.367 94.5925 254.747 94.5925C256.127 94.5925 257.247 95.7119 257.247 97.0925Z"
          fill="#EFEFEF"
        />
        <path
          d="M288.777 97.0925C288.777 98.4732 287.657 99.5925 286.277 99.5925C284.897 99.5925 283.777 98.4732 283.777 97.0925C283.777 95.7119 284.897 94.5925 286.277 94.5925C287.657 94.5925 288.777 95.7119 288.777 97.0925Z"
          fill="#EFEFEF"
        />
        <path
          d="M320.309 97.0925C320.309 98.4732 319.189 99.5925 317.809 99.5925C316.429 99.5925 315.309 98.4732 315.309 97.0925C315.309 95.7119 316.429 94.5925 317.809 94.5925C319.189 94.5925 320.309 95.7119 320.309 97.0925Z"
          fill="#EFEFEF"
        />
        <path
          d="M351.839 97.0925C351.839 98.4732 350.719 99.5925 349.339 99.5925C347.959 99.5925 346.839 98.4732 346.839 97.0925C346.839 95.7119 347.959 94.5925 349.339 94.5925C350.719 94.5925 351.839 95.7119 351.839 97.0925Z"
          fill="#EFEFEF"
        />
        <path
          d="M383.37 97.0925C383.37 98.4732 382.25 99.5925 380.87 99.5925C379.49 99.5925 378.37 98.4732 378.37 97.0925C378.37 95.7119 379.49 94.5925 380.87 94.5925C382.25 94.5925 383.37 95.7119 383.37 97.0925Z"
          fill="#EFEFEF"
        />
        <path
          d="M414.901 2.5C414.901 3.88072 413.781 5 412.401 5C411.021 5 409.901 3.88072 409.901 2.5C409.901 1.11929 411.021 0 412.401 0C413.781 0 414.901 1.11929 414.901 2.5Z"
          fill="#EFEFEF"
        />
        <path
          d="M446.432 2.5C446.432 3.88072 445.312 5 443.932 5C442.552 5 441.432 3.88072 441.432 2.5C441.432 1.11929 442.552 0 443.932 0C445.312 0 446.432 1.11929 446.432 2.5Z"
          fill="#EFEFEF"
        />
        <path
          d="M477.963 2.5C477.963 3.88072 476.843 5 475.463 5C474.083 5 472.963 3.88072 472.963 2.5C472.963 1.11929 474.083 0 475.463 0C476.843 0 477.963 1.11929 477.963 2.5Z"
          fill="#EFEFEF"
        />
        <path
          d="M509.493 2.5C509.493 3.88072 508.373 5 506.993 5C505.613 5 504.493 3.88072 504.493 2.5C504.493 1.11929 505.613 0 506.993 0C508.373 0 509.493 1.11929 509.493 2.5Z"
          fill="#EFEFEF"
        />
        <path
          d="M541.024 2.5C541.024 3.88072 539.904 5 538.524 5C537.144 5 536.024 3.88072 536.024 2.5C536.024 1.11929 537.144 0 538.524 0C539.904 0 541.024 1.11929 541.024 2.5Z"
          fill="#EFEFEF"
        />
        <path
          d="M572.555 2.5C572.555 3.88072 571.435 5 570.055 5C568.675 5 567.555 3.88072 567.555 2.5C567.555 1.11929 568.675 0 570.055 0C571.435 0 572.555 1.11929 572.555 2.5Z"
          fill="#EFEFEF"
        />
        <path
          d="M604.086 2.5C604.086 3.88072 602.966 5 601.586 5C600.206 5 599.086 3.88072 599.086 2.5C599.086 1.11929 600.206 0 601.586 0C602.966 0 604.086 1.11929 604.086 2.5Z"
          fill="#EFEFEF"
        />
        <path
          d="M635.617 2.5C635.617 3.88072 634.497 5 633.117 5C631.737 5 630.617 3.88072 630.617 2.5C630.617 1.11929 631.737 0 633.117 0C634.497 0 635.617 1.11929 635.617 2.5Z"
          fill="#EFEFEF"
        />
        <path
          d="M667.147 2.5C667.147 3.88072 666.027 5 664.647 5C663.267 5 662.147 3.88072 662.147 2.5C662.147 1.11929 663.267 0 664.647 0C666.027 0 667.147 1.11929 667.147 2.5Z"
          fill="#EFEFEF"
        />
        <path
          d="M698.679 2.5C698.679 3.88072 697.559 5 696.179 5C694.799 5 693.679 3.88072 693.679 2.5C693.679 1.11929 694.799 0 696.179 0C697.559 0 698.679 1.11929 698.679 2.5Z"
          fill="#EFEFEF"
        />
        <path
          d="M730.209 2.5C730.209 3.88072 729.089 5 727.709 5C726.329 5 725.209 3.88072 725.209 2.5C725.209 1.11929 726.329 0 727.709 0C729.089 0 730.209 1.11929 730.209 2.5Z"
          fill="#EFEFEF"
        />
        <path
          d="M761.74 2.5C761.74 3.88072 760.62 5 759.24 5C757.86 5 756.74 3.88072 756.74 2.5C756.74 1.11929 757.86 0 759.24 0C760.62 0 761.74 1.11929 761.74 2.5Z"
          fill="#EFEFEF"
        />
        <path
          d="M793.271 2.5C793.271 3.88072 792.151 5 790.771 5C789.391 5 788.271 3.88072 788.271 2.5C788.271 1.11929 789.391 0 790.771 0C792.151 0 793.271 1.11929 793.271 2.5Z"
          fill="#EFEFEF"
        />
        <path
          d="M824.802 2.5C824.802 3.88072 823.682 5 822.302 5C820.922 5 819.802 3.88072 819.802 2.5C819.802 1.11929 820.922 0 822.302 0C823.682 0 824.802 1.11929 824.802 2.5Z"
          fill="#EFEFEF"
        />
        <path
          d="M856.333 2.5C856.333 3.88072 855.213 5 853.833 5C852.453 5 851.333 3.88072 851.333 2.5C851.333 1.11929 852.453 0 853.833 0C855.213 0 856.333 1.11929 856.333 2.5Z"
          fill="#EFEFEF"
        />
        <path
          d="M414.901 34.0308C414.901 35.4114 413.781 36.5308 412.401 36.5308C411.021 36.5308 409.901 35.4114 409.901 34.0308C409.901 32.6501 411.021 31.5308 412.401 31.5308C413.781 31.5308 414.901 32.6501 414.901 34.0308Z"
          fill="#EFEFEF"
        />
        <path
          d="M446.432 34.0308C446.432 35.4114 445.312 36.5308 443.932 36.5308C442.552 36.5308 441.432 35.4114 441.432 34.0308C441.432 32.6501 442.552 31.5308 443.932 31.5308C445.312 31.5308 446.432 32.6501 446.432 34.0308Z"
          fill="#EFEFEF"
        />
        <path
          d="M477.963 34.0308C477.963 35.4114 476.843 36.5308 475.463 36.5308C474.083 36.5308 472.963 35.4114 472.963 34.0308C472.963 32.6501 474.083 31.5308 475.463 31.5308C476.843 31.5308 477.963 32.6501 477.963 34.0308Z"
          fill="#EFEFEF"
        />
        <path
          d="M509.493 34.0308C509.493 35.4114 508.373 36.5308 506.993 36.5308C505.613 36.5308 504.493 35.4114 504.493 34.0308C504.493 32.6501 505.613 31.5308 506.993 31.5308C508.373 31.5308 509.493 32.6501 509.493 34.0308Z"
          fill="#EFEFEF"
        />
        <path
          d="M541.024 34.0308C541.024 35.4114 539.904 36.5308 538.524 36.5308C537.144 36.5308 536.024 35.4114 536.024 34.0308C536.024 32.6501 537.144 31.5308 538.524 31.5308C539.904 31.5308 541.024 32.6501 541.024 34.0308Z"
          fill="#EFEFEF"
        />
        <path
          d="M572.555 34.0308C572.555 35.4114 571.435 36.5308 570.055 36.5308C568.675 36.5308 567.555 35.4114 567.555 34.0308C567.555 32.6501 568.675 31.5308 570.055 31.5308C571.435 31.5308 572.555 32.6501 572.555 34.0308Z"
          fill="#EFEFEF"
        />
        <path
          d="M604.086 34.0308C604.086 35.4114 602.966 36.5308 601.586 36.5308C600.206 36.5308 599.086 35.4114 599.086 34.0308C599.086 32.6501 600.206 31.5308 601.586 31.5308C602.966 31.5308 604.086 32.6501 604.086 34.0308Z"
          fill="#EFEFEF"
        />
        <path
          d="M635.617 34.0308C635.617 35.4114 634.497 36.5308 633.117 36.5308C631.737 36.5308 630.617 35.4114 630.617 34.0308C630.617 32.6501 631.737 31.5308 633.117 31.5308C634.497 31.5308 635.617 32.6501 635.617 34.0308Z"
          fill="#EFEFEF"
        />
        <path
          d="M667.147 34.0308C667.147 35.4114 666.027 36.5308 664.647 36.5308C663.267 36.5308 662.147 35.4114 662.147 34.0308C662.147 32.6501 663.267 31.5308 664.647 31.5308C666.027 31.5308 667.147 32.6501 667.147 34.0308Z"
          fill="#EFEFEF"
        />
        <path
          d="M698.679 34.0308C698.679 35.4114 697.559 36.5308 696.179 36.5308C694.799 36.5308 693.679 35.4114 693.679 34.0308C693.679 32.6501 694.799 31.5308 696.179 31.5308C697.559 31.5308 698.679 32.6501 698.679 34.0308Z"
          fill="#EFEFEF"
        />
        <path
          d="M730.209 34.0308C730.209 35.4114 729.089 36.5308 727.709 36.5308C726.329 36.5308 725.209 35.4114 725.209 34.0308C725.209 32.6501 726.329 31.5308 727.709 31.5308C729.089 31.5308 730.209 32.6501 730.209 34.0308Z"
          fill="#EFEFEF"
        />
        <path
          d="M761.74 34.0308C761.74 35.4114 760.62 36.5308 759.24 36.5308C757.86 36.5308 756.74 35.4114 756.74 34.0308C756.74 32.6501 757.86 31.5308 759.24 31.5308C760.62 31.5308 761.74 32.6501 761.74 34.0308Z"
          fill="#EFEFEF"
        />
        <path
          d="M793.271 34.0308C793.271 35.4114 792.151 36.5308 790.771 36.5308C789.391 36.5308 788.271 35.4114 788.271 34.0308C788.271 32.6501 789.391 31.5308 790.771 31.5308C792.151 31.5308 793.271 32.6501 793.271 34.0308Z"
          fill="#EFEFEF"
        />
        <path
          d="M824.802 34.0308C824.802 35.4114 823.682 36.5308 822.302 36.5308C820.922 36.5308 819.802 35.4114 819.802 34.0308C819.802 32.6501 820.922 31.5308 822.302 31.5308C823.682 31.5308 824.802 32.6501 824.802 34.0308Z"
          fill="#EFEFEF"
        />
        <path
          d="M856.333 34.0308C856.333 35.4114 855.213 36.5308 853.833 36.5308C852.453 36.5308 851.333 35.4114 851.333 34.0308C851.333 32.6501 852.453 31.5308 853.833 31.5308C855.213 31.5308 856.333 32.6501 856.333 34.0308Z"
          fill="#EFEFEF"
        />
        <path
          d="M414.901 65.5615C414.901 66.9422 413.781 68.0615 412.401 68.0615C411.021 68.0615 409.901 66.9422 409.901 65.5615C409.901 64.1809 411.021 63.0615 412.401 63.0615C413.781 63.0615 414.901 64.1809 414.901 65.5615Z"
          fill="#EFEFEF"
        />
        <path
          d="M446.432 65.5615C446.432 66.9422 445.312 68.0615 443.932 68.0615C442.552 68.0615 441.432 66.9422 441.432 65.5615C441.432 64.1809 442.552 63.0615 443.932 63.0615C445.312 63.0615 446.432 64.1809 446.432 65.5615Z"
          fill="#EFEFEF"
        />
        <path
          d="M477.963 65.5615C477.963 66.9422 476.843 68.0615 475.463 68.0615C474.083 68.0615 472.963 66.9422 472.963 65.5615C472.963 64.1809 474.083 63.0615 475.463 63.0615C476.843 63.0615 477.963 64.1809 477.963 65.5615Z"
          fill="#EFEFEF"
        />
        <path
          d="M509.493 65.5615C509.493 66.9422 508.373 68.0615 506.993 68.0615C505.613 68.0615 504.493 66.9422 504.493 65.5615C504.493 64.1809 505.613 63.0615 506.993 63.0615C508.373 63.0615 509.493 64.1809 509.493 65.5615Z"
          fill="#EFEFEF"
        />
        <path
          d="M541.024 65.5615C541.024 66.9422 539.904 68.0615 538.524 68.0615C537.144 68.0615 536.024 66.9422 536.024 65.5615C536.024 64.1809 537.144 63.0615 538.524 63.0615C539.904 63.0615 541.024 64.1809 541.024 65.5615Z"
          fill="#EFEFEF"
        />
        <path
          d="M572.555 65.5615C572.555 66.9422 571.435 68.0615 570.055 68.0615C568.675 68.0615 567.555 66.9422 567.555 65.5615C567.555 64.1809 568.675 63.0615 570.055 63.0615C571.435 63.0615 572.555 64.1809 572.555 65.5615Z"
          fill="#EFEFEF"
        />
        <path
          d="M604.086 65.5615C604.086 66.9422 602.966 68.0615 601.586 68.0615C600.206 68.0615 599.086 66.9422 599.086 65.5615C599.086 64.1809 600.206 63.0615 601.586 63.0615C602.966 63.0615 604.086 64.1809 604.086 65.5615Z"
          fill="#EFEFEF"
        />
        <path
          d="M635.617 65.5615C635.617 66.9422 634.497 68.0615 633.117 68.0615C631.737 68.0615 630.617 66.9422 630.617 65.5615C630.617 64.1809 631.737 63.0615 633.117 63.0615C634.497 63.0615 635.617 64.1809 635.617 65.5615Z"
          fill="#EFEFEF"
        />
        <path
          d="M667.147 65.5615C667.147 66.9422 666.027 68.0615 664.647 68.0615C663.267 68.0615 662.147 66.9422 662.147 65.5615C662.147 64.1809 663.267 63.0615 664.647 63.0615C666.027 63.0615 667.147 64.1809 667.147 65.5615Z"
          fill="#EFEFEF"
        />
        <path
          d="M698.679 65.5615C698.679 66.9422 697.559 68.0615 696.179 68.0615C694.799 68.0615 693.679 66.9422 693.679 65.5615C693.679 64.1809 694.799 63.0615 696.179 63.0615C697.559 63.0615 698.679 64.1809 698.679 65.5615Z"
          fill="#EFEFEF"
        />
        <path
          d="M730.209 65.5615C730.209 66.9422 729.089 68.0615 727.709 68.0615C726.329 68.0615 725.209 66.9422 725.209 65.5615C725.209 64.1809 726.329 63.0615 727.709 63.0615C729.089 63.0615 730.209 64.1809 730.209 65.5615Z"
          fill="#EFEFEF"
        />
        <path
          d="M761.74 65.5615C761.74 66.9422 760.62 68.0615 759.24 68.0615C757.86 68.0615 756.74 66.9422 756.74 65.5615C756.74 64.1809 757.86 63.0615 759.24 63.0615C760.62 63.0615 761.74 64.1809 761.74 65.5615Z"
          fill="#EFEFEF"
        />
        <path
          d="M793.271 65.5615C793.271 66.9422 792.151 68.0615 790.771 68.0615C789.391 68.0615 788.271 66.9422 788.271 65.5615C788.271 64.1809 789.391 63.0615 790.771 63.0615C792.151 63.0615 793.271 64.1809 793.271 65.5615Z"
          fill="#EFEFEF"
        />
        <path
          d="M824.802 65.5615C824.802 66.9422 823.682 68.0615 822.302 68.0615C820.922 68.0615 819.802 66.9422 819.802 65.5615C819.802 64.1809 820.922 63.0615 822.302 63.0615C823.682 63.0615 824.802 64.1809 824.802 65.5615Z"
          fill="#EFEFEF"
        />
        <path
          d="M856.333 65.5615C856.333 66.9422 855.213 68.0615 853.833 68.0615C852.453 68.0615 851.333 66.9422 851.333 65.5615C851.333 64.1809 852.453 63.0615 853.833 63.0615C855.213 63.0615 856.333 64.1809 856.333 65.5615Z"
          fill="#EFEFEF"
        />
        <path
          d="M414.901 97.0925C414.901 98.4732 413.781 99.5925 412.401 99.5925C411.021 99.5925 409.901 98.4732 409.901 97.0925C409.901 95.7119 411.021 94.5925 412.401 94.5925C413.781 94.5925 414.901 95.7119 414.901 97.0925Z"
          fill="#EFEFEF"
        />
        <path
          d="M446.432 97.0925C446.432 98.4732 445.312 99.5925 443.932 99.5925C442.552 99.5925 441.432 98.4732 441.432 97.0925C441.432 95.7119 442.552 94.5925 443.932 94.5925C445.312 94.5925 446.432 95.7119 446.432 97.0925Z"
          fill="#EFEFEF"
        />
        <path
          d="M477.963 97.0925C477.963 98.4732 476.843 99.5925 475.463 99.5925C474.083 99.5925 472.963 98.4732 472.963 97.0925C472.963 95.7119 474.083 94.5925 475.463 94.5925C476.843 94.5925 477.963 95.7119 477.963 97.0925Z"
          fill="#EFEFEF"
        />
        <path
          d="M509.493 97.0925C509.493 98.4732 508.373 99.5925 506.993 99.5925C505.613 99.5925 504.493 98.4732 504.493 97.0925C504.493 95.7119 505.613 94.5925 506.993 94.5925C508.373 94.5925 509.493 95.7119 509.493 97.0925Z"
          fill="#EFEFEF"
        />
        <path
          d="M541.024 97.0925C541.024 98.4732 539.904 99.5925 538.524 99.5925C537.144 99.5925 536.024 98.4732 536.024 97.0925C536.024 95.7119 537.144 94.5925 538.524 94.5925C539.904 94.5925 541.024 95.7119 541.024 97.0925Z"
          fill="#EFEFEF"
        />
        <path
          d="M572.555 97.0925C572.555 98.4732 571.435 99.5925 570.055 99.5925C568.675 99.5925 567.555 98.4732 567.555 97.0925C567.555 95.7119 568.675 94.5925 570.055 94.5925C571.435 94.5925 572.555 95.7119 572.555 97.0925Z"
          fill="#EFEFEF"
        />
        <path
          d="M604.086 97.0925C604.086 98.4732 602.966 99.5925 601.586 99.5925C600.206 99.5925 599.086 98.4732 599.086 97.0925C599.086 95.7119 600.206 94.5925 601.586 94.5925C602.966 94.5925 604.086 95.7119 604.086 97.0925Z"
          fill="#EFEFEF"
        />
        <path
          d="M635.617 97.0925C635.617 98.4732 634.497 99.5925 633.117 99.5925C631.737 99.5925 630.617 98.4732 630.617 97.0925C630.617 95.7119 631.737 94.5925 633.117 94.5925C634.497 94.5925 635.617 95.7119 635.617 97.0925Z"
          fill="#EFEFEF"
        />
        <path
          d="M667.147 97.0925C667.147 98.4732 666.027 99.5925 664.647 99.5925C663.267 99.5925 662.147 98.4732 662.147 97.0925C662.147 95.7119 663.267 94.5925 664.647 94.5925C666.027 94.5925 667.147 95.7119 667.147 97.0925Z"
          fill="#EFEFEF"
        />
        <path
          d="M698.679 97.0925C698.679 98.4732 697.559 99.5925 696.179 99.5925C694.799 99.5925 693.679 98.4732 693.679 97.0925C693.679 95.7119 694.799 94.5925 696.179 94.5925C697.559 94.5925 698.679 95.7119 698.679 97.0925Z"
          fill="#EFEFEF"
        />
        <path
          d="M730.209 97.0925C730.209 98.4732 729.089 99.5925 727.709 99.5925C726.329 99.5925 725.209 98.4732 725.209 97.0925C725.209 95.7119 726.329 94.5925 727.709 94.5925C729.089 94.5925 730.209 95.7119 730.209 97.0925Z"
          fill="#EFEFEF"
        />
        <path
          d="M761.74 97.0925C761.74 98.4732 760.62 99.5925 759.24 99.5925C757.86 99.5925 756.74 98.4732 756.74 97.0925C756.74 95.7119 757.86 94.5925 759.24 94.5925C760.62 94.5925 761.74 95.7119 761.74 97.0925Z"
          fill="#EFEFEF"
        />
        <path
          d="M793.271 97.0925C793.271 98.4732 792.151 99.5925 790.771 99.5925C789.391 99.5925 788.271 98.4732 788.271 97.0925C788.271 95.7119 789.391 94.5925 790.771 94.5925C792.151 94.5925 793.271 95.7119 793.271 97.0925Z"
          fill="#EFEFEF"
        />
        <path
          d="M824.802 97.0925C824.802 98.4732 823.682 99.5925 822.302 99.5925C820.922 99.5925 819.802 98.4732 819.802 97.0925C819.802 95.7119 820.922 94.5925 822.302 94.5925C823.682 94.5925 824.802 95.7119 824.802 97.0925Z"
          fill="#EFEFEF"
        />
        <path
          d="M856.333 97.0925C856.333 98.4732 855.213 99.5925 853.833 99.5925C852.453 99.5925 851.333 98.4732 851.333 97.0925C851.333 95.7119 852.453 94.5925 853.833 94.5925C855.213 94.5925 856.333 95.7119 856.333 97.0925Z"
          fill="#EFEFEF"
        />
        <path
          d="M887.863 2.5C887.863 3.88072 886.743 5 885.363 5C883.983 5 882.863 3.88072 882.863 2.5C882.863 1.11929 883.983 0 885.363 0C886.743 0 887.863 1.11929 887.863 2.5Z"
          fill="#EFEFEF"
        />
        <path
          d="M919.395 2.5C919.395 3.88072 918.275 5 916.895 5C915.515 5 914.395 3.88072 914.395 2.5C914.395 1.11929 915.515 0 916.895 0C918.275 0 919.395 1.11929 919.395 2.5Z"
          fill="#EFEFEF"
        />
        <path
          d="M950.925 2.5C950.925 3.88072 949.805 5 948.425 5C947.045 5 945.925 3.88072 945.925 2.5C945.925 1.11929 947.045 0 948.425 0C949.805 0 950.925 1.11929 950.925 2.5Z"
          fill="#EFEFEF"
        />
        <path
          d="M982.456 2.5C982.456 3.88072 981.336 5 979.956 5C978.576 5 977.456 3.88072 977.456 2.5C977.456 1.11929 978.576 0 979.956 0C981.336 0 982.456 1.11929 982.456 2.5Z"
          fill="#EFEFEF"
        />
        <path
          d="M1013.99 2.5C1013.99 3.88072 1012.87 5 1011.49 5C1010.11 5 1008.99 3.88072 1008.99 2.5C1008.99 1.11929 1010.11 0 1011.49 0C1012.87 0 1013.99 1.11929 1013.99 2.5Z"
          fill="#EFEFEF"
        />
        <path
          d="M1045.52 2.5C1045.52 3.88072 1044.4 5 1043.02 5C1041.64 5 1040.52 3.88072 1040.52 2.5C1040.52 1.11929 1041.64 0 1043.02 0C1044.4 0 1045.52 1.11929 1045.52 2.5Z"
          fill="#EFEFEF"
        />
        <path
          d="M1077.05 2.5C1077.05 3.88072 1075.93 5 1074.55 5C1073.17 5 1072.05 3.88072 1072.05 2.5C1072.05 1.11929 1073.17 0 1074.55 0C1075.93 0 1077.05 1.11929 1077.05 2.5Z"
          fill="#EFEFEF"
        />
        <path
          d="M1108.58 2.5C1108.58 3.88072 1107.46 5 1106.08 5C1104.7 5 1103.58 3.88072 1103.58 2.5C1103.58 1.11929 1104.7 0 1106.08 0C1107.46 0 1108.58 1.11929 1108.58 2.5Z"
          fill="#EFEFEF"
        />
        <path
          d="M1140.11 2.5C1140.11 3.88072 1138.99 5 1137.61 5C1136.23 5 1135.11 3.88072 1135.11 2.5C1135.11 1.11929 1136.23 0 1137.61 0C1138.99 0 1140.11 1.11929 1140.11 2.5Z"
          fill="#EFEFEF"
        />
        <path
          d="M1171.64 2.5C1171.64 3.88072 1170.52 5 1169.14 5C1167.76 5 1166.64 3.88072 1166.64 2.5C1166.64 1.11929 1167.76 0 1169.14 0C1170.52 0 1171.64 1.11929 1171.64 2.5Z"
          fill="#EFEFEF"
        />
        <path
          d="M1203.17 2.5C1203.17 3.88072 1202.05 5 1200.67 5C1199.29 5 1198.17 3.88072 1198.17 2.5C1198.17 1.11929 1199.29 0 1200.67 0C1202.05 0 1203.17 1.11929 1203.17 2.5Z"
          fill="#EFEFEF"
        />
        <path
          d="M1234.7 2.5C1234.7 3.88072 1233.58 5 1232.2 5C1230.82 5 1229.7 3.88072 1229.7 2.5C1229.7 1.11929 1230.82 0 1232.2 0C1233.58 0 1234.7 1.11929 1234.7 2.5Z"
          fill="#EFEFEF"
        />
        <path
          d="M1266.23 2.5C1266.23 3.88072 1265.11 5 1263.73 5C1262.35 5 1261.23 3.88072 1261.23 2.5C1261.23 1.11929 1262.35 0 1263.73 0C1265.11 0 1266.23 1.11929 1266.23 2.5Z"
          fill="#EFEFEF"
        />
        <path
          d="M1297.76 2.5C1297.76 3.88072 1296.64 5 1295.26 5C1293.88 5 1292.76 3.88072 1292.76 2.5C1292.76 1.11929 1293.88 0 1295.26 0C1296.64 0 1297.76 1.11929 1297.76 2.5Z"
          fill="#EFEFEF"
        />
        <path
          d="M1329.3 2.5C1329.3 3.88072 1328.18 5 1326.8 5C1325.42 5 1324.3 3.88072 1324.3 2.5C1324.3 1.11929 1325.42 0 1326.8 0C1328.18 0 1329.3 1.11929 1329.3 2.5Z"
          fill="#EFEFEF"
        />
        <path
          d="M887.863 34.0308C887.863 35.4114 886.743 36.5308 885.363 36.5308C883.983 36.5308 882.863 35.4114 882.863 34.0308C882.863 32.6501 883.983 31.5308 885.363 31.5308C886.743 31.5308 887.863 32.6501 887.863 34.0308Z"
          fill="#EFEFEF"
        />
        <path
          d="M919.395 34.0308C919.395 35.4114 918.275 36.5308 916.895 36.5308C915.515 36.5308 914.395 35.4114 914.395 34.0308C914.395 32.6501 915.515 31.5308 916.895 31.5308C918.275 31.5308 919.395 32.6501 919.395 34.0308Z"
          fill="#EFEFEF"
        />
        <path
          d="M950.925 34.0308C950.925 35.4114 949.805 36.5308 948.425 36.5308C947.045 36.5308 945.925 35.4114 945.925 34.0308C945.925 32.6501 947.045 31.5308 948.425 31.5308C949.805 31.5308 950.925 32.6501 950.925 34.0308Z"
          fill="#EFEFEF"
        />
        <path
          d="M982.456 34.0308C982.456 35.4114 981.336 36.5308 979.956 36.5308C978.576 36.5308 977.456 35.4114 977.456 34.0308C977.456 32.6501 978.576 31.5308 979.956 31.5308C981.336 31.5308 982.456 32.6501 982.456 34.0308Z"
          fill="#EFEFEF"
        />
        <path
          d="M1013.99 34.0308C1013.99 35.4114 1012.87 36.5308 1011.49 36.5308C1010.11 36.5308 1008.99 35.4114 1008.99 34.0308C1008.99 32.6501 1010.11 31.5308 1011.49 31.5308C1012.87 31.5308 1013.99 32.6501 1013.99 34.0308Z"
          fill="#EFEFEF"
        />
        <path
          d="M1045.52 34.0308C1045.52 35.4114 1044.4 36.5308 1043.02 36.5308C1041.64 36.5308 1040.52 35.4114 1040.52 34.0308C1040.52 32.6501 1041.64 31.5308 1043.02 31.5308C1044.4 31.5308 1045.52 32.6501 1045.52 34.0308Z"
          fill="#EFEFEF"
        />
        <path
          d="M1077.05 34.0308C1077.05 35.4114 1075.93 36.5308 1074.55 36.5308C1073.17 36.5308 1072.05 35.4114 1072.05 34.0308C1072.05 32.6501 1073.17 31.5308 1074.55 31.5308C1075.93 31.5308 1077.05 32.6501 1077.05 34.0308Z"
          fill="#EFEFEF"
        />
        <path
          d="M1108.58 34.0308C1108.58 35.4114 1107.46 36.5308 1106.08 36.5308C1104.7 36.5308 1103.58 35.4114 1103.58 34.0308C1103.58 32.6501 1104.7 31.5308 1106.08 31.5308C1107.46 31.5308 1108.58 32.6501 1108.58 34.0308Z"
          fill="#EFEFEF"
        />
        <path
          d="M1140.11 34.0308C1140.11 35.4114 1138.99 36.5308 1137.61 36.5308C1136.23 36.5308 1135.11 35.4114 1135.11 34.0308C1135.11 32.6501 1136.23 31.5308 1137.61 31.5308C1138.99 31.5308 1140.11 32.6501 1140.11 34.0308Z"
          fill="#EFEFEF"
        />
        <path
          d="M1171.64 34.0308C1171.64 35.4114 1170.52 36.5308 1169.14 36.5308C1167.76 36.5308 1166.64 35.4114 1166.64 34.0308C1166.64 32.6501 1167.76 31.5308 1169.14 31.5308C1170.52 31.5308 1171.64 32.6501 1171.64 34.0308Z"
          fill="#EFEFEF"
        />
        <path
          d="M1203.17 34.0308C1203.17 35.4114 1202.05 36.5308 1200.67 36.5308C1199.29 36.5308 1198.17 35.4114 1198.17 34.0308C1198.17 32.6501 1199.29 31.5308 1200.67 31.5308C1202.05 31.5308 1203.17 32.6501 1203.17 34.0308Z"
          fill="#EFEFEF"
        />
        <path
          d="M1234.7 34.0308C1234.7 35.4114 1233.58 36.5308 1232.2 36.5308C1230.82 36.5308 1229.7 35.4114 1229.7 34.0308C1229.7 32.6501 1230.82 31.5308 1232.2 31.5308C1233.58 31.5308 1234.7 32.6501 1234.7 34.0308Z"
          fill="#EFEFEF"
        />
        <path
          d="M1266.23 34.0308C1266.23 35.4114 1265.11 36.5308 1263.73 36.5308C1262.35 36.5308 1261.23 35.4114 1261.23 34.0308C1261.23 32.6501 1262.35 31.5308 1263.73 31.5308C1265.11 31.5308 1266.23 32.6501 1266.23 34.0308Z"
          fill="#EFEFEF"
        />
        <path
          d="M1297.76 34.0308C1297.76 35.4114 1296.64 36.5308 1295.26 36.5308C1293.88 36.5308 1292.76 35.4114 1292.76 34.0308C1292.76 32.6501 1293.88 31.5308 1295.26 31.5308C1296.64 31.5308 1297.76 32.6501 1297.76 34.0308Z"
          fill="#EFEFEF"
        />
        <path
          d="M1329.3 34.0308C1329.3 35.4114 1328.18 36.5308 1326.8 36.5308C1325.42 36.5308 1324.3 35.4114 1324.3 34.0308C1324.3 32.6501 1325.42 31.5308 1326.8 31.5308C1328.18 31.5308 1329.3 32.6501 1329.3 34.0308Z"
          fill="#EFEFEF"
        />
        <path
          d="M887.863 65.5615C887.863 66.9422 886.743 68.0615 885.363 68.0615C883.983 68.0615 882.863 66.9422 882.863 65.5615C882.863 64.1809 883.983 63.0615 885.363 63.0615C886.743 63.0615 887.863 64.1809 887.863 65.5615Z"
          fill="#EFEFEF"
        />
        <path
          d="M919.395 65.5615C919.395 66.9422 918.275 68.0615 916.895 68.0615C915.515 68.0615 914.395 66.9422 914.395 65.5615C914.395 64.1809 915.515 63.0615 916.895 63.0615C918.275 63.0615 919.395 64.1809 919.395 65.5615Z"
          fill="#EFEFEF"
        />
        <path
          d="M950.925 65.5615C950.925 66.9422 949.805 68.0615 948.425 68.0615C947.045 68.0615 945.925 66.9422 945.925 65.5615C945.925 64.1809 947.045 63.0615 948.425 63.0615C949.805 63.0615 950.925 64.1809 950.925 65.5615Z"
          fill="#EFEFEF"
        />
        <path
          d="M982.456 65.5615C982.456 66.9422 981.336 68.0615 979.956 68.0615C978.576 68.0615 977.456 66.9422 977.456 65.5615C977.456 64.1809 978.576 63.0615 979.956 63.0615C981.336 63.0615 982.456 64.1809 982.456 65.5615Z"
          fill="#EFEFEF"
        />
        <path
          d="M1013.99 65.5615C1013.99 66.9422 1012.87 68.0615 1011.49 68.0615C1010.11 68.0615 1008.99 66.9422 1008.99 65.5615C1008.99 64.1809 1010.11 63.0615 1011.49 63.0615C1012.87 63.0615 1013.99 64.1809 1013.99 65.5615Z"
          fill="#EFEFEF"
        />
        <path
          d="M1045.52 65.5615C1045.52 66.9422 1044.4 68.0615 1043.02 68.0615C1041.64 68.0615 1040.52 66.9422 1040.52 65.5615C1040.52 64.1809 1041.64 63.0615 1043.02 63.0615C1044.4 63.0615 1045.52 64.1809 1045.52 65.5615Z"
          fill="#EFEFEF"
        />
        <path
          d="M1077.05 65.5615C1077.05 66.9422 1075.93 68.0615 1074.55 68.0615C1073.17 68.0615 1072.05 66.9422 1072.05 65.5615C1072.05 64.1809 1073.17 63.0615 1074.55 63.0615C1075.93 63.0615 1077.05 64.1809 1077.05 65.5615Z"
          fill="#EFEFEF"
        />
        <path
          d="M1108.58 65.5615C1108.58 66.9422 1107.46 68.0615 1106.08 68.0615C1104.7 68.0615 1103.58 66.9422 1103.58 65.5615C1103.58 64.1809 1104.7 63.0615 1106.08 63.0615C1107.46 63.0615 1108.58 64.1809 1108.58 65.5615Z"
          fill="#EFEFEF"
        />
        <path
          d="M1140.11 65.5615C1140.11 66.9422 1138.99 68.0615 1137.61 68.0615C1136.23 68.0615 1135.11 66.9422 1135.11 65.5615C1135.11 64.1809 1136.23 63.0615 1137.61 63.0615C1138.99 63.0615 1140.11 64.1809 1140.11 65.5615Z"
          fill="#EFEFEF"
        />
        <path
          d="M1171.64 65.5615C1171.64 66.9422 1170.52 68.0615 1169.14 68.0615C1167.76 68.0615 1166.64 66.9422 1166.64 65.5615C1166.64 64.1809 1167.76 63.0615 1169.14 63.0615C1170.52 63.0615 1171.64 64.1809 1171.64 65.5615Z"
          fill="#EFEFEF"
        />
        <path
          d="M1203.17 65.5615C1203.17 66.9422 1202.05 68.0615 1200.67 68.0615C1199.29 68.0615 1198.17 66.9422 1198.17 65.5615C1198.17 64.1809 1199.29 63.0615 1200.67 63.0615C1202.05 63.0615 1203.17 64.1809 1203.17 65.5615Z"
          fill="#EFEFEF"
        />
        <path
          d="M1234.7 65.5615C1234.7 66.9422 1233.58 68.0615 1232.2 68.0615C1230.82 68.0615 1229.7 66.9422 1229.7 65.5615C1229.7 64.1809 1230.82 63.0615 1232.2 63.0615C1233.58 63.0615 1234.7 64.1809 1234.7 65.5615Z"
          fill="#EFEFEF"
        />
        <path
          d="M1266.23 65.5615C1266.23 66.9422 1265.11 68.0615 1263.73 68.0615C1262.35 68.0615 1261.23 66.9422 1261.23 65.5615C1261.23 64.1809 1262.35 63.0615 1263.73 63.0615C1265.11 63.0615 1266.23 64.1809 1266.23 65.5615Z"
          fill="#EFEFEF"
        />
        <path
          d="M1297.76 65.5615C1297.76 66.9422 1296.64 68.0615 1295.26 68.0615C1293.88 68.0615 1292.76 66.9422 1292.76 65.5615C1292.76 64.1809 1293.88 63.0615 1295.26 63.0615C1296.64 63.0615 1297.76 64.1809 1297.76 65.5615Z"
          fill="#EFEFEF"
        />
        <path
          d="M1329.3 65.5615C1329.3 66.9422 1328.18 68.0615 1326.8 68.0615C1325.42 68.0615 1324.3 66.9422 1324.3 65.5615C1324.3 64.1809 1325.42 63.0615 1326.8 63.0615C1328.18 63.0615 1329.3 64.1809 1329.3 65.5615Z"
          fill="#EFEFEF"
        />
        <path
          d="M887.863 97.0925C887.863 98.4732 886.743 99.5925 885.363 99.5925C883.983 99.5925 882.863 98.4732 882.863 97.0925C882.863 95.7119 883.983 94.5925 885.363 94.5925C886.743 94.5925 887.863 95.7119 887.863 97.0925Z"
          fill="#EFEFEF"
        />
        <path
          d="M919.395 97.0925C919.395 98.4732 918.275 99.5925 916.895 99.5925C915.515 99.5925 914.395 98.4732 914.395 97.0925C914.395 95.7119 915.515 94.5925 916.895 94.5925C918.275 94.5925 919.395 95.7119 919.395 97.0925Z"
          fill="#EFEFEF"
        />
        <path
          d="M950.925 97.0925C950.925 98.4732 949.805 99.5925 948.425 99.5925C947.045 99.5925 945.925 98.4732 945.925 97.0925C945.925 95.7119 947.045 94.5925 948.425 94.5925C949.805 94.5925 950.925 95.7119 950.925 97.0925Z"
          fill="#EFEFEF"
        />
        <path
          d="M982.456 97.0925C982.456 98.4732 981.336 99.5925 979.956 99.5925C978.576 99.5925 977.456 98.4732 977.456 97.0925C977.456 95.7119 978.576 94.5925 979.956 94.5925C981.336 94.5925 982.456 95.7119 982.456 97.0925Z"
          fill="#EFEFEF"
        />
        <path
          d="M1013.99 97.0925C1013.99 98.4732 1012.87 99.5925 1011.49 99.5925C1010.11 99.5925 1008.99 98.4732 1008.99 97.0925C1008.99 95.7119 1010.11 94.5925 1011.49 94.5925C1012.87 94.5925 1013.99 95.7119 1013.99 97.0925Z"
          fill="#EFEFEF"
        />
        <path
          d="M1045.52 97.0925C1045.52 98.4732 1044.4 99.5925 1043.02 99.5925C1041.64 99.5925 1040.52 98.4732 1040.52 97.0925C1040.52 95.7119 1041.64 94.5925 1043.02 94.5925C1044.4 94.5925 1045.52 95.7119 1045.52 97.0925Z"
          fill="#EFEFEF"
        />
        <path
          d="M1077.05 97.0925C1077.05 98.4732 1075.93 99.5925 1074.55 99.5925C1073.17 99.5925 1072.05 98.4732 1072.05 97.0925C1072.05 95.7119 1073.17 94.5925 1074.55 94.5925C1075.93 94.5925 1077.05 95.7119 1077.05 97.0925Z"
          fill="#EFEFEF"
        />
        <path
          d="M1108.58 97.0925C1108.58 98.4732 1107.46 99.5925 1106.08 99.5925C1104.7 99.5925 1103.58 98.4732 1103.58 97.0925C1103.58 95.7119 1104.7 94.5925 1106.08 94.5925C1107.46 94.5925 1108.58 95.7119 1108.58 97.0925Z"
          fill="#EFEFEF"
        />
        <path
          d="M1140.11 97.0925C1140.11 98.4732 1138.99 99.5925 1137.61 99.5925C1136.23 99.5925 1135.11 98.4732 1135.11 97.0925C1135.11 95.7119 1136.23 94.5925 1137.61 94.5925C1138.99 94.5925 1140.11 95.7119 1140.11 97.0925Z"
          fill="#EFEFEF"
        />
        <path
          d="M1171.64 97.0925C1171.64 98.4732 1170.52 99.5925 1169.14 99.5925C1167.76 99.5925 1166.64 98.4732 1166.64 97.0925C1166.64 95.7119 1167.76 94.5925 1169.14 94.5925C1170.52 94.5925 1171.64 95.7119 1171.64 97.0925Z"
          fill="#EFEFEF"
        />
        <path
          d="M1203.17 97.0925C1203.17 98.4732 1202.05 99.5925 1200.67 99.5925C1199.29 99.5925 1198.17 98.4732 1198.17 97.0925C1198.17 95.7119 1199.29 94.5925 1200.67 94.5925C1202.05 94.5925 1203.17 95.7119 1203.17 97.0925Z"
          fill="#EFEFEF"
        />
        <path
          d="M1234.7 97.0925C1234.7 98.4732 1233.58 99.5925 1232.2 99.5925C1230.82 99.5925 1229.7 98.4732 1229.7 97.0925C1229.7 95.7119 1230.82 94.5925 1232.2 94.5925C1233.58 94.5925 1234.7 95.7119 1234.7 97.0925Z"
          fill="#EFEFEF"
        />
        <path
          d="M1266.23 97.0925C1266.23 98.4732 1265.11 99.5925 1263.73 99.5925C1262.35 99.5925 1261.23 98.4732 1261.23 97.0925C1261.23 95.7119 1262.35 94.5925 1263.73 94.5925C1265.11 94.5925 1266.23 95.7119 1266.23 97.0925Z"
          fill="#EFEFEF"
        />
        <path
          d="M1297.76 97.0925C1297.76 98.4732 1296.64 99.5925 1295.26 99.5925C1293.88 99.5925 1292.76 98.4732 1292.76 97.0925C1292.76 95.7119 1293.88 94.5925 1295.26 94.5925C1296.64 94.5925 1297.76 95.7119 1297.76 97.0925Z"
          fill="#EFEFEF"
        />
        <path
          d="M1329.3 97.0925C1329.3 98.4732 1328.18 99.5925 1326.8 99.5925C1325.42 99.5925 1324.3 98.4732 1324.3 97.0925C1324.3 95.7119 1325.42 94.5925 1326.8 94.5925C1328.18 94.5925 1329.3 95.7119 1329.3 97.0925Z"
          fill="#EFEFEF"
        />
        <path
          d="M1360.83 2.5C1360.83 3.88072 1359.71 5 1358.33 5C1356.95 5 1355.83 3.88072 1355.83 2.5C1355.83 1.11929 1356.95 0 1358.33 0C1359.71 0 1360.83 1.11929 1360.83 2.5Z"
          fill="#EFEFEF"
        />
        <path
          d="M1392.36 2.5C1392.36 3.88072 1391.24 5 1389.86 5C1388.48 5 1387.36 3.88072 1387.36 2.5C1387.36 1.11929 1388.48 0 1389.86 0C1391.24 0 1392.36 1.11929 1392.36 2.5Z"
          fill="#EFEFEF"
        />
        <path
          d="M1423.89 2.5C1423.89 3.88072 1422.77 5 1421.39 5C1420.01 5 1418.89 3.88072 1418.89 2.5C1418.89 1.11929 1420.01 0 1421.39 0C1422.77 0 1423.89 1.11929 1423.89 2.5Z"
          fill="#EFEFEF"
        />
        <path
          d="M1455.42 2.5C1455.42 3.88072 1454.3 5 1452.92 5C1451.54 5 1450.42 3.88072 1450.42 2.5C1450.42 1.11929 1451.54 0 1452.92 0C1454.3 0 1455.42 1.11929 1455.42 2.5Z"
          fill="#EFEFEF"
        />
        <path
          d="M1486.95 2.5C1486.95 3.88072 1485.83 5 1484.45 5C1483.07 5 1481.95 3.88072 1481.95 2.5C1481.95 1.11929 1483.07 0 1484.45 0C1485.83 0 1486.95 1.11929 1486.95 2.5Z"
          fill="#EFEFEF"
        />
        <path
          d="M1518.48 2.5C1518.48 3.88072 1517.36 5 1515.98 5C1514.6 5 1513.48 3.88072 1513.48 2.5C1513.48 1.11929 1514.6 0 1515.98 0C1517.36 0 1518.48 1.11929 1518.48 2.5Z"
          fill="#EFEFEF"
        />
        <path
          d="M1550.01 2.5C1550.01 3.88072 1548.89 5 1547.51 5C1546.13 5 1545.01 3.88072 1545.01 2.5C1545.01 1.11929 1546.13 0 1547.51 0C1548.89 0 1550.01 1.11929 1550.01 2.5Z"
          fill="#EFEFEF"
        />
        <path
          d="M1581.54 2.5C1581.54 3.88072 1580.43 5 1579.04 5C1577.66 5 1576.54 3.88072 1576.54 2.5C1576.54 1.11929 1577.66 0 1579.04 0C1580.43 0 1581.54 1.11929 1581.54 2.5Z"
          fill="#EFEFEF"
        />
        <path
          d="M1613.07 2.5C1613.07 3.88072 1611.96 5 1610.57 5C1609.19 5 1608.07 3.88072 1608.07 2.5C1608.07 1.11929 1609.19 0 1610.57 0C1611.96 0 1613.07 1.11929 1613.07 2.5Z"
          fill="#EFEFEF"
        />
        <path
          d="M1644.6 2.5C1644.6 3.88072 1643.49 5 1642.1 5C1640.72 5 1639.6 3.88072 1639.6 2.5C1639.6 1.11929 1640.72 0 1642.1 0C1643.49 0 1644.6 1.11929 1644.6 2.5Z"
          fill="#EFEFEF"
        />
        <path
          d="M1360.83 34.0308C1360.83 35.4114 1359.71 36.5308 1358.33 36.5308C1356.95 36.5308 1355.83 35.4114 1355.83 34.0308C1355.83 32.6501 1356.95 31.5308 1358.33 31.5308C1359.71 31.5308 1360.83 32.6501 1360.83 34.0308Z"
          fill="#EFEFEF"
        />
        <path
          d="M1392.36 34.0308C1392.36 35.4114 1391.24 36.5308 1389.86 36.5308C1388.48 36.5308 1387.36 35.4114 1387.36 34.0308C1387.36 32.6501 1388.48 31.5308 1389.86 31.5308C1391.24 31.5308 1392.36 32.6501 1392.36 34.0308Z"
          fill="#EFEFEF"
        />
        <path
          d="M1423.89 34.0308C1423.89 35.4114 1422.77 36.5308 1421.39 36.5308C1420.01 36.5308 1418.89 35.4114 1418.89 34.0308C1418.89 32.6501 1420.01 31.5308 1421.39 31.5308C1422.77 31.5308 1423.89 32.6501 1423.89 34.0308Z"
          fill="#EFEFEF"
        />
        <path
          d="M1455.42 34.0308C1455.42 35.4114 1454.3 36.5308 1452.92 36.5308C1451.54 36.5308 1450.42 35.4114 1450.42 34.0308C1450.42 32.6501 1451.54 31.5308 1452.92 31.5308C1454.3 31.5308 1455.42 32.6501 1455.42 34.0308Z"
          fill="#EFEFEF"
        />
        <path
          d="M1486.95 34.0308C1486.95 35.4114 1485.83 36.5308 1484.45 36.5308C1483.07 36.5308 1481.95 35.4114 1481.95 34.0308C1481.95 32.6501 1483.07 31.5308 1484.45 31.5308C1485.83 31.5308 1486.95 32.6501 1486.95 34.0308Z"
          fill="#EFEFEF"
        />
        <path
          d="M1518.48 34.0308C1518.48 35.4114 1517.36 36.5308 1515.98 36.5308C1514.6 36.5308 1513.48 35.4114 1513.48 34.0308C1513.48 32.6501 1514.6 31.5308 1515.98 31.5308C1517.36 31.5308 1518.48 32.6501 1518.48 34.0308Z"
          fill="#EFEFEF"
        />
        <path
          d="M1550.01 34.0308C1550.01 35.4114 1548.89 36.5308 1547.51 36.5308C1546.13 36.5308 1545.01 35.4114 1545.01 34.0308C1545.01 32.6501 1546.13 31.5308 1547.51 31.5308C1548.89 31.5308 1550.01 32.6501 1550.01 34.0308Z"
          fill="#EFEFEF"
        />
        <path
          d="M1581.54 34.0308C1581.54 35.4114 1580.43 36.5308 1579.04 36.5308C1577.66 36.5308 1576.54 35.4114 1576.54 34.0308C1576.54 32.6501 1577.66 31.5308 1579.04 31.5308C1580.43 31.5308 1581.54 32.6501 1581.54 34.0308Z"
          fill="#EFEFEF"
        />
        <path
          d="M1613.07 34.0308C1613.07 35.4114 1611.96 36.5308 1610.57 36.5308C1609.19 36.5308 1608.07 35.4114 1608.07 34.0308C1608.07 32.6501 1609.19 31.5308 1610.57 31.5308C1611.96 31.5308 1613.07 32.6501 1613.07 34.0308Z"
          fill="#EFEFEF"
        />
        <path
          d="M1360.83 65.5615C1360.83 66.9422 1359.71 68.0615 1358.33 68.0615C1356.95 68.0615 1355.83 66.9422 1355.83 65.5615C1355.83 64.1809 1356.95 63.0615 1358.33 63.0615C1359.71 63.0615 1360.83 64.1809 1360.83 65.5615Z"
          fill="#EFEFEF"
        />
        <path
          d="M1392.36 65.5615C1392.36 66.9422 1391.24 68.0615 1389.86 68.0615C1388.48 68.0615 1387.36 66.9422 1387.36 65.5615C1387.36 64.1809 1388.48 63.0615 1389.86 63.0615C1391.24 63.0615 1392.36 64.1809 1392.36 65.5615Z"
          fill="#EFEFEF"
        />
        <path
          d="M1423.89 65.5615C1423.89 66.9422 1422.77 68.0615 1421.39 68.0615C1420.01 68.0615 1418.89 66.9422 1418.89 65.5615C1418.89 64.1809 1420.01 63.0615 1421.39 63.0615C1422.77 63.0615 1423.89 64.1809 1423.89 65.5615Z"
          fill="#EFEFEF"
        />
        <path
          d="M1455.42 65.5615C1455.42 66.9422 1454.3 68.0615 1452.92 68.0615C1451.54 68.0615 1450.42 66.9422 1450.42 65.5615C1450.42 64.1809 1451.54 63.0615 1452.92 63.0615C1454.3 63.0615 1455.42 64.1809 1455.42 65.5615Z"
          fill="#EFEFEF"
        />
        <path
          d="M1486.95 65.5615C1486.95 66.9422 1485.83 68.0615 1484.45 68.0615C1483.07 68.0615 1481.95 66.9422 1481.95 65.5615C1481.95 64.1809 1483.07 63.0615 1484.45 63.0615C1485.83 63.0615 1486.95 64.1809 1486.95 65.5615Z"
          fill="#EFEFEF"
        />
        <path
          d="M1518.48 65.5615C1518.48 66.9422 1517.36 68.0615 1515.98 68.0615C1514.6 68.0615 1513.48 66.9422 1513.48 65.5615C1513.48 64.1809 1514.6 63.0615 1515.98 63.0615C1517.36 63.0615 1518.48 64.1809 1518.48 65.5615Z"
          fill="#EFEFEF"
        />
        <path
          d="M1550.01 65.5615C1550.01 66.9422 1548.89 68.0615 1547.51 68.0615C1546.13 68.0615 1545.01 66.9422 1545.01 65.5615C1545.01 64.1809 1546.13 63.0615 1547.51 63.0615C1548.89 63.0615 1550.01 64.1809 1550.01 65.5615Z"
          fill="#EFEFEF"
        />
        <path
          d="M1581.54 65.5615C1581.54 66.9422 1580.43 68.0615 1579.04 68.0615C1577.66 68.0615 1576.54 66.9422 1576.54 65.5615C1576.54 64.1809 1577.66 63.0615 1579.04 63.0615C1580.43 63.0615 1581.54 64.1809 1581.54 65.5615Z"
          fill="#EFEFEF"
        />
        <path
          d="M1360.83 97.0925C1360.83 98.4732 1359.71 99.5925 1358.33 99.5925C1356.95 99.5925 1355.83 98.4732 1355.83 97.0925C1355.83 95.7119 1356.95 94.5925 1358.33 94.5925C1359.71 94.5925 1360.83 95.7119 1360.83 97.0925Z"
          fill="#EFEFEF"
        />
        <path
          d="M1392.36 97.0925C1392.36 98.4732 1391.24 99.5925 1389.86 99.5925C1388.48 99.5925 1387.36 98.4732 1387.36 97.0925C1387.36 95.7119 1388.48 94.5925 1389.86 94.5925C1391.24 94.5925 1392.36 95.7119 1392.36 97.0925Z"
          fill="#EFEFEF"
        />
        <path
          d="M1423.89 97.0925C1423.89 98.4732 1422.77 99.5925 1421.39 99.5925C1420.01 99.5925 1418.89 98.4732 1418.89 97.0925C1418.89 95.7119 1420.01 94.5925 1421.39 94.5925C1422.77 94.5925 1423.89 95.7119 1423.89 97.0925Z"
          fill="#EFEFEF"
        />
        <path
          d="M1455.42 97.0925C1455.42 98.4732 1454.3 99.5925 1452.92 99.5925C1451.54 99.5925 1450.42 98.4732 1450.42 97.0925C1450.42 95.7119 1451.54 94.5925 1452.92 94.5925C1454.3 94.5925 1455.42 95.7119 1455.42 97.0925Z"
          fill="#EFEFEF"
        />
        <path
          d="M1486.95 97.0925C1486.95 98.4732 1485.83 99.5925 1484.45 99.5925C1483.07 99.5925 1481.95 98.4732 1481.95 97.0925C1481.95 95.7119 1483.07 94.5925 1484.45 94.5925C1485.83 94.5925 1486.95 95.7119 1486.95 97.0925Z"
          fill="#EFEFEF"
        />
        <path
          d="M1518.48 97.0925C1518.48 98.4732 1517.36 99.5925 1515.98 99.5925C1514.6 99.5925 1513.48 98.4732 1513.48 97.0925C1513.48 95.7119 1514.6 94.5925 1515.98 94.5925C1517.36 94.5925 1518.48 95.7119 1518.48 97.0925Z"
          fill="#EFEFEF"
        />
        <path
          d="M1550.01 97.0925C1550.01 98.4732 1548.89 99.5925 1547.51 99.5925C1546.13 99.5925 1545.01 98.4732 1545.01 97.0925C1545.01 95.7119 1546.13 94.5925 1547.51 94.5925C1548.89 94.5925 1550.01 95.7119 1550.01 97.0925Z"
          fill="#EFEFEF"
        />
        <path
          d="M5 2.5C5 3.88072 3.88072 5 2.5 5C1.11929 5 0 3.88072 0 2.5C0 1.11929 1.11929 0 2.5 0C3.88072 0 5 1.11929 5 2.5Z"
          fill="#EFEFEF"
        />
        <path
          d="M36.5308 34.0308C36.5308 35.4114 35.4114 36.5308 34.0308 36.5308C32.6501 36.5308 31.5308 35.4114 31.5308 34.0308C31.5308 32.6501 32.6501 31.5308 34.0308 31.5308C35.4114 31.5308 36.5308 32.6501 36.5308 34.0308Z"
          fill="#EFEFEF"
        />
        <path
          d="M68.0615 65.5615C68.0615 66.9422 66.9422 68.0615 65.5615 68.0615C64.1809 68.0615 63.0615 66.9422 63.0615 65.5615C63.0615 64.1809 64.1809 63.0615 65.5615 63.0615C66.9422 63.0615 68.0615 64.1809 68.0615 65.5615Z"
          fill="#EFEFEF"
        />
        <path
          d="M99.5928 97.0925C99.5928 98.4732 98.4734 99.5925 97.0928 99.5925C95.7121 99.5925 94.5928 98.4732 94.5928 97.0925C94.5928 95.7119 95.7121 94.5925 97.0928 94.5925C98.4734 94.5925 99.5928 95.7119 99.5928 97.0925Z"
          fill="#EFEFEF"
        />
      </g>
    </svg>
  );
}
