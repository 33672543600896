import React, { useEffect } from "react";
import ServiceForm from "./ServiceForm";
import ServicesDesc from "./ServicesDesc";
import { useSetHeaderContext } from "../root/header/header.context";

const Services = () => {
  const setHeader = useSetHeaderContext();
  useEffect(() => {
    setHeader({
      bannerImage: "/assets/header-services.jpg",
      title: "Services",
      description:
        "Regular calibration ensures that your measurements are consistently reliable, so you’ll always enjoy peace of mind in your work.",
    });
    document.title = "Services";
  }, []);

  return (
    <div>
      <ServicesDesc />
      <ServiceForm />
    </div>
  );
};

export default Services;
