import React, { useEffect, useRef, useState } from "react";
import "./slider.css";
import { HeaderComponent } from "../../pages/root/header/header.component";

export const SliderHeaderComponent = ({ slides }) => {
  const sliderIndexRef = useRef(0);
  const [sliderIndex, setSliderIndex] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      nextSlide();
    }, 5000);

    return () => clearInterval(interval);
  }, [slides]);

  const nextSlide = () => {
    if (sliderIndexRef.current < slides.length - 1) {
      setSliderIndex((sliderIndexRef.current += 1));
    } else if (sliderIndexRef.current === slides.length - 1) {
      sliderIndexRef.current = 0;
      setSliderIndex(0);
    }
  };

  const prevSlide = () => {
    if (sliderIndexRef.current > 0) {
      setSliderIndex((sliderIndexRef.current -= 1));
    } else if (sliderIndexRef.current === 0) {
      sliderIndexRef.current = slides.length - 1;
      setSliderIndex(slides.length - 1);
    }
  };

  const moveDot = (index) => {
    sliderIndexRef.current = index;
    setSliderIndex(index);
  };

  return (
    <div
      className="container-slider-header"
    >
      {slides.map((slide, index) => {
        return (
          <div
            key={index}
            className={sliderIndex === index ? "slide active-slide" : "slide"}
          >
            <HeaderComponent
              bannerImage={slide.banner_image}
              button={slide.button}
              description={slide.brief_description}
              title={slide.name}
              titleSize="lg"
            />
          </div>
        );
      })}
{/* 
      <div className="container-dots">
        {slides.map((slide, index) => (
          <div
            style={{ cursor: "pointer" }}
            key={index}
            onClick={() => moveDot(index)}
            className={sliderIndex === index ? "dot active-dot" : "dot"}
          />
        ))}
      </div> */}
    </div>
  );
};
