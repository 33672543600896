import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Tab } from "@mui/material";
import { createTheme } from "@mui/material/styles";

export function TabComponent({ tabs, tab, setTab }) {
  const theme = createTheme({
    components: {
      MuiTabPanel: {
        styleOverrides: {
          root: {
            paddingLeft: "0px",
            paddingRight: "0px",
            paddingBottom: "0px",
            paddingTop: ".5rem",
            flexGrow: 1,
            overflow: "auto",
          },
        },
      },
    },
  });

  return (
    <div
      className="w-full h-full flex flex-col"
      style={{ display: "flex", padding: "1rem" }}
    >
      <TabContext value={tab}>
        <TabList
          sx={{
            "& .MuiTabs-scroller": {
              overflowX: "auto !important",
            },
            "& .MuiButtonBase-root": {
              fontSize: "80%",
            },
          }}
          value={tab}
          onChange={(event, value) => {
            setTab(value);
          }}
          className="top-[-1.2vh] sticky z-[9] bg-white"
        >
          {tabs.map((tab, index) => {
            return (
              <Tab
                label={tab.label}
                className="text-xs"
                value={index.toString()}
                key={`tab${index}`}
                disabled={tab.disabled}
              />
            );
          })}
        </TabList>
        {tabs.map((tab, index) => {
          return (
            <TabPanel
              theme={theme}
              value={index.toString()}
              key={`tabpanel-${index}`}
            >
              {tab.component}
            </TabPanel>
          );
        })}
      </TabContext>
    </div>
  );
}
