import {
  ExpandCircleDown,
  ExpandLessRounded,
  ExpandLessSharp,
  ExpandMoreOutlined,
  ExpandMoreRounded,
  ExpandMoreSharp,
} from "@mui/icons-material";
import { Checkbox, FormControlLabel, FormGroup, Grid } from "@mui/material";
import { useState } from "react";

export function FilterComponent({
  title,
  items,
  filterKey,
  handleFilter,
  itemKey,
  expandable = true,
  checkedFilters,
}) {
  const [expanded, setExpanded] = useState(true);

  return (
    <>
      <Grid item container xs={12}>
        <Grid item container xs={12} style={{ alignItems: "center" }}>
          <h5 style={{ flex: "1", margin: 0 }}>{title}</h5>
          {expandable ? (
            expanded ? (
              <ExpandLessSharp onClick={() => setExpanded(false)} />
            ) : (
              <ExpandMoreSharp onClick={() => setExpanded(true)} />
            )
          ) : (
            <></>
          )}
        </Grid>
        {expanded &&
          items.map((item) => {
            return (
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checkedFilters[filterKey].includes(
                        itemKey ? item[itemKey] : item
                      )}
                      sx={{
                        "& .MuiCheckbox-root": {
                          // padding: 0,
                        },
                      }}
                      onChange={(e) =>
                        handleFilter(
                          e.target.checked,
                          itemKey ? item[itemKey] : item,
                          filterKey
                        )
                      }
                    />
                  }
                  label={<span>{itemKey ? item[itemKey] : item}</span>}
                />
              </Grid>
            );
          })}
      </Grid>
    </>
  );
}
