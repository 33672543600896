export function drawerReducer(state, action) {
  switch (action.type) {
    case "open": {
      return { ...drawerReducerState, open: true, ...action.payload };
    }
    case "close": {
      return { open: false };
    }
  }
}

export const drawerReducerState = {
  open: false,
  content: <></>,
  fullHeight: false,
  width: "50vw",
  title: null,
  key: false,
};
