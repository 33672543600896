import { createContext, useContext, useReducer } from "react";
import { drawerReducer, drawerReducerState } from "./drawer.reducer";

export const DrawerContext = createContext(null);
export const CloseDrawerContext = createContext(null);
export const OpenDrawerContext = createContext(null);

export function useDrawerContext() {
  return useContext(DrawerContext);
}

export function useOpenDrawerDispatch() {
  return useContext(OpenDrawerContext);
}

export function useCloseDrawerDispatch() {
  return useContext(CloseDrawerContext);
}

export function DrawerProvider({ children }) {
  const [drawerState, drawerDispatch] = useReducer(
    drawerReducer,
    drawerReducerState
  );

  return (
    <DrawerContext.Provider value={drawerState}>
      <OpenDrawerContext.Provider
        value={(payload) => {
          drawerDispatch({ type: "open", payload });
        }}
      >
        <CloseDrawerContext.Provider
          value={() =>
            drawerDispatch({
              type: "close",
            })
          }
        >
          {children}
        </CloseDrawerContext.Provider>
      </OpenDrawerContext.Provider>
    </DrawerContext.Provider>
  );
}
