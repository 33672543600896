import { useLocation, useParams } from "react-router";
import { useEffect, useState } from "react";
import { getScrollbarWidth } from "../../helpers/getscrollbarwidth";
import { Link } from "react-router-dom";
import { Breadcrumbs, Button, Divider, Typography } from "@mui/material";
import axios from "axios";
import { url } from "../../constants";
import { ContactFormComponent } from "./contactform.component";
import { DrawerComponent } from "./drawer/drawer.component";
import { FooterV2Component } from "./footer/footerv2.component";
import { HeaderComponent } from "./header/header.component";
import { useHeaderContext } from "./header/header.context";
import Navbar from "../../components/navbar/Navbar";
import { BrowserNavbarComponent } from "../../components/navbar/browsernavbar.component";
import { SliderHeaderComponent } from "../../components/slider/sliderheader.component";

export function RootComponent({ children }) {
  const location = useLocation();
  const [contactDialog, setContactDialog] = useState(false);
  const [snackbar, setSnackbar] = useState(false);
  const [slides, setSlides] = useState([]);
  let breadCrumpPath = "";

  const header = useHeaderContext();
  const paths = location.pathname.split("/");

  useEffect(() => {
    let slides = [];

    axios
      .get(`${url}/api/v1/cms/advertisements`)
      .then((response) => {
        setSlides((prevState) => [
          ...prevState,
          ...response.data.map((data) => {
            if (JSON.parse(data.link).to != null) {
              return {
                ...data,
                button: (
                  <Link to={JSON.parse(data.link).to}>
                    <Button className="submit-btn" variant="contained">
                      Learn More
                    </Button>
                  </Link>
                ),
              };
            }
            return data;
          }),
          {
            name: "Oxen System ",
            brief_description:
              "  A complete solution provider for Singapore's construction industry.",
            button: (
              <Link to={`/about-us`}>
                <Button className="submit-btn" variant="contained">
                  About Us
                </Button>
              </Link>
            ),
          },
        ]);
      })
      .then(() => {
        axios.get(`${url}/api/v1/cms/solutions`).then((response) => {
          setSlides((prevState) => [
            ...prevState,
            ...response.data.map((data) => {
              return {
                ...data,
                button: (
                  <Link to={`/solutions/${data.dir_name}`}>
                    <Button className="submit-btn" variant="contained">
                      Learn More
                    </Button>
                  </Link>
                ),
              };
            }),
          ]);
        });
      });

    setSlides(slides);
  }, []);

  return (
    <>
      <DrawerComponent />
      {window.visualViewport.width < 1080 ? (
        <Navbar />
      ) : (
        <BrowserNavbarComponent />
      )}
      {window.visualViewport.width > 1200 && (
        <div
          onClick={() => setContactDialog(true)}
          style={{
            zIndex: "21",
            position: "absolute",
            top: "25vh",
            right: getScrollbarWidth(),
            writingMode: "tb-rl",
            background: "#28a5df",
            padding: ".75rem",
            cursor: "pointer",
            borderRadius: ".5rem 0 0 .5rem",
          }}
        >
          <span
            style={{
              color: "white",
            }}
          >
            Contact Us
          </span>
        </div>
      )}
      <div style={{ position: "relative", top: "80px" }}>
        {location.pathname == "/" ? (
          <SliderHeaderComponent slides={slides} />
        ) : (
          <HeaderComponent
            bannerImage={header.bannerImage}
            button={header.button}
            description={header.description}
            descriptionTitle={header.descriptionTitle}
            title={header.title}
            titleSize={header.titleSize}
          />
        )}
        {location.pathname != "/" && (
          <>
            <div
              style={{
                width:
                  window.visualViewport.width < 1080
                    ? "100vw"
                    : window.visualViewport.width < 1600
                    ? "85vw"
                    : "75vw",
                margin: "auto",
                padding: "1rem",
                boxSizing: "border-box",
                textDecoration: "none",
              }}
            >
              <Breadcrumbs aria-label="breadcrumb">
                {paths.map((path, index) => {
                  breadCrumpPath =
                    breadCrumpPath == ""
                      ? path
                      : index != 0
                      ? `${breadCrumpPath}/${path}`
                      : breadCrumpPath;
                  return index == 0 ? (
                    <Link
                      underline="hover"
                      color="inherit"
                      to={`/`}
                      style={{ textDecoration: "none", color: "black" }}
                    >
                      Home
                    </Link>
                  ) : (
                    <Link
                      underline="hover"
                      color="inherit"
                      to={`/${breadCrumpPath}`}
                      style={{
                        textDecoration: "none",
                        color: index == paths.length - 1 ? "#28a5df" : "black",
                      }}
                    >
                      {path}
                    </Link>
                  );
                })}
              </Breadcrumbs>
            </div>
            <Divider sx={{ opacity: "0.5", height: "0.1px" }} />
          </>
        )}
        {children}
        <FooterV2Component />
      </div>
      {contactDialog && (
        <ContactFormComponent
          open={contactDialog}
          // subject={`Enquiry for ${productName}-${itemName}`}
          handleDialog={setContactDialog}
          setSnackbar={setSnackbar}
        />
      )}
    </>
  );
}
