export function headerReducer(state, action) {
  switch (action.type) {
    case "setHeader": {
      return { ...headerReducerState, ...action.payload };
    }
  }
}

export const headerReducerState = {
  bannerImage: undefined,
  title: undefined,
  descriptionTitle: undefined,
  description: undefined,
  button: undefined,
  titleSize: "lg",
};
