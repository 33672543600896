import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { HeaderProvider } from "./pages/root/header/header.context";
import { FilterProvider } from "./pages/products/filters.context";
import { DrawerProvider } from "./pages/root/drawer/drawer.context";

ReactDOM.render(
  <React.StrictMode>
    <DrawerProvider>
      <HeaderProvider>
        <FilterProvider>
          <App />
        </FilterProvider>
      </HeaderProvider>
    </DrawerProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
