export function filterHelper(checkedFilters, products) {
  let filteredProducts = [];
  products.forEach((product) => {
    if (checkedFilters.product.length > 0) {
      if (checkedFilters.product.includes(product.name)) {
        if (
          checkedFilters.brand.length > 0 ||
          checkedFilters.filter.length > 0
        ) {
          if (
            checkedFilters.brand.length > 0 &&
            checkedFilters.filter.length > 0
          ) {
            filteredProducts.push({
              ...product,
              productItems: product.productItems.filter(
                (item) =>
                  checkedFilters.brand.includes(item.brand) &&
                  checkedFilters.filter.some((filter) =>
                    JSON.parse(item.filters).includes(filter)
                  )
              ),
            });
          } else if (checkedFilters.brand.length > 0) {
            filteredProducts.push({
              ...product,
              productItems: product.productItems.filter((item) =>
                checkedFilters.brand.includes(item.brand)
              ),
            });
          } else if (checkedFilters.filter.length > 0) {
            filteredProducts.push({
              ...product,
              productItems: product.productItems.filter((item) =>
                checkedFilters.filter.some((filter) =>
                  JSON.parse(item.filters).includes(filter)
                )
              ),
            });
          }
        } else {
          filteredProducts.push(product);
        }
      }
    } else {
      if (checkedFilters.brand.length > 0 || checkedFilters.filter.length > 0) {
        if (
          checkedFilters.brand.length > 0 &&
          checkedFilters.filter.length > 0
        ) {
          filteredProducts.push({
            ...product,
            productItems: product.productItems.filter(
              (item) =>
                checkedFilters.brand.includes(item.brand) &&
                checkedFilters.filter.some((filter) =>
                  JSON.parse(item.filters).includes(filter)
                )
            ),
          });
        } else if (checkedFilters.brand.length > 0) {
          filteredProducts.push({
            ...product,
            productItems: product.productItems.filter((item) =>
              checkedFilters.brand.includes(item.brand)
            ),
          });
        } else if (checkedFilters.filter.length > 0) {
          filteredProducts.push({
            ...product,
            productItems: product.productItems.filter((item) =>
              checkedFilters.filter.some((filter) =>
                JSON.parse(item.filters).includes(filter)
              )
            ),
          });
        }
      }
    }
  });
  filteredProducts = filteredProducts.filter(
    (product) => product.productItems.length != 0
  );

  return filteredProducts;
}

export function filterHelperV2(checkedFilters, products) {
  let filteredProducts = [];
  if (checkedFilters.product.length > 0) {
    let toFilter = filteredProducts.length > 0 ? filteredProducts : products;
    filteredProducts = toFilter.filter((product) =>
      checkedFilters.product.includes(product.name)
    );
  }
  if (checkedFilters.brand.length > 0) {
    let toFilter = filteredProducts.length > 0 ? filteredProducts : products;
    filteredProducts = toFilter.map((product) => {
      return {
        ...product,
        productItems: product.productItems.filter((item) =>
          checkedFilters.brand.includes(item.brand)
        ),
      };
    });
  }
  if (checkedFilters.filter.length > 0) {
    let toFilter = filteredProducts.length > 0 ? filteredProducts : products;
    filteredProducts = toFilter.map((product) => {
      return {
        ...product,
        productItems: product.productItems.filter((item) =>
          checkedFilters.filter.some((filter) =>
            JSON.parse(item.filters).includes(filter)
          )
        ),
      };
    });
  }

  if (checkedFilters.search.length > 0) {
    let toFilter = filteredProducts.length > 0 ? filteredProducts : products;
    filteredProducts = toFilter.map((product) => {
      return {
        ...product,
        productItems: product.productItems.filter((item) =>
          item.name.toLowerCase().includes(checkedFilters.search.toLowerCase())
        ),
      };
    });
  }

  return filteredProducts.filter((product) => product.productItems.length > 0);
}
