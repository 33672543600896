import { useState } from "react";

export function FeatureComponent({ features }) {
  const [selectedFeature, setSelectedFeature] = useState(features[0]);

  return (
    <>
      <div
        className="toggle-wrapper"
        style={{ width: "100%", maxWidth: "100%" }}
      >
        {features.map((feature, index) => {
          return (
            <div
              className={
                index == 0 && !selectedFeature
                  ? "toggled-header"
                  : feature.title === selectedFeature?.title
                  ? "toggled-header"
                  : "untoggled-header"
              }
              onClick={() => setSelectedFeature(feature)}
            >
              {feature.title}
            </div>
          );
        })}
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          width: "100%",
        }}
      >
        {selectedFeature.features.map((feature) => {
          return (
            <>
              <div className="flex-align-start">
                <i className="fas fa-check-circle" />
                <p>{feature.value}</p>
              </div>
              {/* <div className="flex-align-start">
                <i className="fas fa-check-circle" />
                <p>{feature.value}</p>
              </div>
              <div className="flex-align-start">
                <i className="fas fa-check-circle" />
                <p>{feature.value}</p>
              </div> */}
            </>
          );
        })}
      </div>
    </>
  );
}
