import {
  Link,
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
  useLocation,
} from "react-router-dom";
import "./App.css";
import Solutions from "./pages/solutions/Solutions";
import "react-multi-carousel/lib/styles.css";
import { SolutionComponent } from "./pages/solutions/solution.component";
import Services from "./pages/services/Services";
import { ProductComponent } from "./pages/products/product.component";
import Products from "./pages/products/products.component";
import { ItemComponent } from "./pages/item/item.component";
import { Helmet } from "react-helmet";
import Home from "./pages/home/Home";
import About from "./pages/about/About";
import { RootComponent } from "./pages/root/root.component";

function App() {
  return (
    <>
      <Helmet>
        <meta
          name="description"
          content="NEA and PUB approved monitoring systems and IOT solutions provider"
        />
      </Helmet>
      <Router>
        <RootComponent>
          <Routes>
            <Route path="/" exact="true" element={<Home />} />
            <Route path="/about-us" element={<About />} />
            <Route path="/solutions" element={<Solutions />} />
            <Route
              path="/solutions/:solutionName"
              element={<SolutionComponent />}
            />
            <Route path="/services" element={<Services />} />

            <Route path="/products" element={<Products />} />
            <Route
              path="/products/:productName"
              element={<ProductComponent />}
            />
            <Route
              path="/products/:productName/:itemName"
              element={<ItemComponent />}
            />

            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        </RootComponent>
        {/* <Footer /> */}
      </Router>
    </>
  );
}

export default App;
