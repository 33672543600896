import { createContext, useContext, useReducer } from "react";
import { headerReducer, headerReducerState } from "./header.reducer";

export const HeaderContext = createContext(null);
export const SetHeaderContext = createContext(null);

export function useHeaderContext() {
  return useContext(HeaderContext);
}

export function useSetHeaderContext() {
  return useContext(SetHeaderContext);
}

export function HeaderProvider({ children }) {
  const [headerState, headerDispatch] = useReducer(
    headerReducer,
    headerReducerState
  );

  return (
    <HeaderContext.Provider value={headerState}>
      <SetHeaderContext.Provider
        value={(payload) => headerDispatch({ type: "setHeader", payload })}
      >
        {children}
      </SetHeaderContext.Provider>
    </HeaderContext.Provider>
  );
}
