import { Grid } from "@mui/material";
import { Link } from "react-router-dom";

export function ProductItemThumbnailComponent({
  link,
  state,
  image,
  title,
  itemCount = 4,
}) {
  return (
    <Grid item container xs={6} md={12 / itemCount}>
      <Link
        to={link}
        state={state}
        style={{ textDecoration: "none", width: "100%" }}
      >
        <Grid item container xs={12} direction={"column"} alignItems={"center"} rowGap={1}>
          <Grid
            item
            xs={12}
            style={{
              width: "100%",
              border: "solid 1px rgb(0,0,0,.1)",
              padding: "1rem",
              borderRadius: "1rem",
            }}
          >
            <img
              height="150px"
              src={image ?? "/assets/placeholder.png"}
              style={{ width: "100%", objectFit: "contain" }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            justifyItems="center"
            display={"flex"}
            direction={"column"}
            alignItems={"flex-start"}
            width={"100%"}
            rowGap={1}
          >
            <span style={{ color: "black", textAlign: "unset", margin: "0" }}>
              {title}
            </span>
            <span style={{ color: "#28a5df" }}>Click to See Details</span>
          </Grid>
        </Grid>
      </Link>
    </Grid>
  );
}
