import { Divider, Grid } from "@mui/material";
import "./footer.css";

export function FooterV2Component() {
  return (
    <Grid
      container
      style={{
        position: "relative",
        // top: "80px",
        flexBasis: "0",
        // marginTop: "1rem",
      }}
      direction={"column"}
      rowSpacing={2}
    >
      <Grid
        item
        container
        xs={12}
        style={{
          minHeight: "200px",
          background: `linear-gradient(90.13deg, #3BC7FD 40%, 
       #176EBB 107.37%)`,
        }}
        direction={"column"}
        flexBasis={"unset"}
      >
        {/* <div style={{ position: "unset" }}>
          <DotDecoComponent />
        </div> */}
        <Grid
          item
          container
          xs={12}
          style={{
            width: window.visualViewport.width < 1080?"100vw":window.visualViewport.width<1600 ? "85vw" : "75vw",
            margin: "auto",
            flexBasis: "unset",
            alignItems: "flex-start",
          }}
          alignItems={"center"}
        >
          <Grid item xs={12} md={6} container style={{ padding: "1rem" }}>
            <Grid item xs={2} >
              <i
                className="fas fa-phone-alt"
                style={{
                  //   border: "solid 1px",
                  borderRadius: "50%",
                  padding: ".5rem",
                  background: "black",
                  color: "white",
                  width: "20px",
                  height: "20px",
                  textAlign: "center",
                  fontSize: "20px",
                }}
              />
            </Grid>
            <Grid item xs={10} >
              <h5 style={{ color: "white",  margin: "0" }}>
                Call us now on
              </h5>
              <p
                style={{
                  color: "white",
                }}
              >
                Tel: +65 6748 0468 | +65 6748 1556
              </p>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6} container style={{ padding: "1rem" }}>
            <Grid item xs={2} >
              <i
                className="fas fa-map-marker-alt"
                style={{
                  //   border: "solid 1px",
                  borderRadius: "50%",
                  padding: ".5rem",
                  background: "black",
                  color: "white",
                  width: "20px",
                  height: "20px",
                  textAlign: "center",
                  fontSize: "20px",
                }}
              />
            </Grid>
            <Grid item xs={10}>
              <h5 style={{ color: "white",margin: "0" }}>
                Location
              </h5>
              <p
                style={{
                  color: "white",
                }}
              >
                Blk 1013 Geylang East Ave 3, #03-144 Singapore 389728
              </p>
              <p
                style={{
                  color: "white",
                }}
              >
                Blk 1014 Geylang East Ave 3, #06-174 Singapore 389728
              </p>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        container
        xs={12}
        style={{ width:window.visualViewport.width<1600?"85vw":"75vw", margin: "auto", alignItems: "center" }}
      >
        <Grid item xs={12} md={6} style={{ padding: "1rem" }}>
          <img
            src="/assets/website_logo_transparent_background.png"
            alt="logo"
            style={{ width: "20%" }}
            // className="logo"
          />
          <p>
            Oxen is an IT technology company specialising in the distribution
            and maintenance of monitoring systems for construction sites.
          </p>
          <span>
            <i className="fas fa-envelope" /> sales@oxen.com.sg
          </span>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          className="img-hover-wrapper"
          style={{ height: "100%" }}
        >
          <img
            src="/assets/location.png"
            alt="location"
            className="location-map"
            // width={"25%"}
            style={{ maxHeight: "100%", maxWidth: "100%" }}
          />
          <div className="hover-btn-wrapper">
            <a
              target="_blank"
              href="https://www.google.com/maps/place/1013+Geylang+East+Ave+3,+03+144,+Singapore+389728/@1.3190356,103.8885599,17z/data=!3m1!4b1!4m5!3m4!1s0x31da18228680cd4d:0xfe0f7e4f3203bfb6!8m2!3d1.3190356!4d103.8907486"
            >
              <button className="hover-btn">View Map</button>
            </a>
          </div>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Divider sx={{ opacity: "0.5",height:'0.1px' }} />
      </Grid>
      <Grid item xs={12} justifyContent={"center"} alignItems={"center"}>
        <p className="copyright">
          © {new Date().getUTCFullYear()} Oxen Technologies Pte Ltd.
        </p>
      </Grid>
    </Grid>
  );
}
