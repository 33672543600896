import { createContext, useContext, useReducer } from "react";
import {
  CheckedFiltersReducer,
  checkedFiltersState,
} from "./checkedfiltersreducer";

const FilterContext = createContext(null);
const SetFilterContext = createContext(null);
const SetSearchContext = createContext(null);
const ResetFiltersContext = createContext(null);
const ResetAllContext = createContext(null);

export function useFilterContext() {
  return useContext(FilterContext);
}

export function useSetFilterContext() {
  return useContext(SetFilterContext);
}

export function useSetSearchContext() {
  return useContext(SetSearchContext);
}

export function useResetFiltersContext() {
  return useContext(ResetFiltersContext);
}

export function useResetAllContext() {
  return useContext(ResetAllContext);
}

export function FilterProvider({ children }) {
  const [checkedFilters, setCheckedFilters] = useReducer(
    CheckedFiltersReducer,
    checkedFiltersState
  );

  return (
    <FilterContext.Provider value={checkedFilters}>
      <SetFilterContext.Provider
        value={(payload, key) =>
          setCheckedFilters({ type: "setFilter", payload, key })
        }
      >
        <SetSearchContext.Provider
          value={(payload) => setCheckedFilters({ type: "setSearch", payload })}
        >
          <ResetFiltersContext.Provider
            value={() => setCheckedFilters({ type: "resetFilters" })}
          >
            <ResetAllContext.Provider
              value={() => setCheckedFilters({ type: "resetAll" })}
            >
              {children}
            </ResetAllContext.Provider>
          </ResetFiltersContext.Provider>
        </SetSearchContext.Provider>
      </SetFilterContext.Provider>
    </FilterContext.Provider>
  );
}
