import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "./navbar.css";

import {
  ArrowForward,
  CloseRounded,
  HandymanRounded,
  Home,
  ListAltOutlined,
  MenuRounded,
} from "@mui/icons-material";
import { Grid } from "@mui/material";
import {
  useCloseDrawerDispatch,
  useDrawerContext,
  useOpenDrawerDispatch,
} from "../../pages/root/drawer/drawer.context";

const menuList = [
  { to: "/", name: "Home", icon: <Home /> },
  { to: "/solutions", name: "Solutions", icon: <HandymanRounded /> },
  { to: "/products", name: "Products" },
  { to: "/services", name: "Services" },
  { to: "/about-us", name: "About Us" },
];

function Navbar() {
  const openDrawer = useOpenDrawerDispatch();
  const closeDrawer = useCloseDrawerDispatch();
  const drawerState = useDrawerContext();
  const [click, setClick] = useState(false);
  const location = useLocation();

  const handleDrawerOpen = () =>
    openDrawer({
      content: (
        <Grid
          container
          style={{
            width: "100%",
            textAlign: "right",
          }}
        >
          {menuList.map((menu) => (
            <>
              <Grid
                item
                xs={12}
                className="nav-item"
                style={{
                  background:
                    menu.to != "/"
                      ? location.pathname.includes(menu.to) && "#28a5df"
                      : menu.to == location.pathname && "#28a5df",
                  padding: ".5rem",
                }}
              >
                <Link
                  exact="true"
                  to={menu.to}
                  activeclassname="active"
                  className="nav-links"
                  onClick={closeDrawer}
                  style={{
                    color:
                      menu.to != "/"
                        ? location.pathname.includes(menu.to) && "white"
                        : menu.to == location.pathname && "white",
                  }}
                >
                  {menu.name}
                </Link>
              </Grid>
            </>
          ))}
        </Grid>
      ),
    });

  return (
    <Grid
      container
      className="navbar"
      style={{
        position: "absolute",
        top: "0",
        width: "100%",
        background: "white",
        padding: "1rem",
      }}
    >
      <Grid item xs={11} className="nav-logo">
        <Link exact="true" to="/" className="logo">
          <img
            src="/assets/website_logo_transparent_background.png"
            alt="logo"
            width={"100%"}
            height={"100%"}
          />
        </Link>
      </Grid>
      <Grid
        item
        xs={1}
        onClick={() => {
          drawerState.open ? closeDrawer() : handleDrawerOpen();
        }}
        display={"flex"}
        alignItems={"center"}
      >
        {drawerState.open ? (
          <CloseRounded fontSize="medium" color="primary" />
        ) : (
          <MenuRounded fontSize="medium" color="primary" />
        )}
      </Grid>
    </Grid>
  );
}

export default Navbar;
