import React, { useEffect, useState } from "react";
import axios from "axios";
import { url } from "../../constants";
import { Grid } from "@mui/material";
import { SolutionThmbnailComponent } from "./solutionthumbnail.component";
import { Helmet } from "react-helmet";
import { useSetHeaderContext } from "../root/header/header.context";

const Solutions = () => {
  const setHeader = useSetHeaderContext();

  const [solutions, setSolutions] = useState([]);
  useEffect(() => {
    setHeader({
      bannerImage: "/assets/header-solutions.jpg",
      description:
        "  Complete consturction solutions that meets government's regulations.",
      title: "Solutions",
    });
    axios.get(`${url}/api/v1/cms/solutions`).then((response) => {
      setSolutions(response.data);
    });
  }, []);

  return (
    <>
      <Helmet>
        <title>Monitoring Solutions</title>
        <meta
          name="description"
          content="Complete consturction moniotring solutions that meets government's
          regulations."
        />
      </Helmet>
      <div style={{ marginBottom: "5rem" }}>
        <Grid
          container
          rowGap={2}
          columnSpacing={window.visualViewport.width < 1080 ? 0 : 2}
          style={{
            padding: window.visualViewport.width < 1080 && "1rem",
            width:
              window.visualViewport.width < 1080
                ? "100vw"
                : window.visualViewport.width < 1600
                ? "85vw"
                : "75vw",
            margin: "auto",
          }}
        >
          {solutions.map((solution) => {
            return (
              <Grid
                item
                xs={12}
                md={4}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  height:
                    window.visualViewport.width < 1920 ? "300px" : "500px",
                }}
              >
                <SolutionThmbnailComponent solution={solution} />
              </Grid>
            );
          })}
        </Grid>
        {/* <SolutionsGallery /> */}
        {/* <Enquiry /> */}
      </div>
    </>
  );
};

export default Solutions;
