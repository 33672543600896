import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
} from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { url } from "../../constants";

export function ContactFormComponent({
  open,
  subject,
  handleDialog,
  setSnackbar,
}) {
  const [enquiry, setEnquiry] = useState({
    name: "",
    contact: "",
    fromEmail: "",
    subject,
    content: "",
  });
  function handleChange(event) {
    const { name, value } = event.target;
    setEnquiry((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  }

  async function handleSubmit(event) {
    event.preventDefault();
    await axios
      .post(`${url}/api/public/enquiry`, enquiry)
      .then(() => {
        setSnackbar(true);
        setEnquiry({
          name: "",
          contact: "",
          fromEmail: "",
          subject: "",
          content: "",
        });
      })
      .catch((error) =>{});
  }

  return (
    <Dialog
      open={open}
      maxWidth="md"
      fullWidth
      onClose={() => handleDialog(!open)}
    >
      <DialogTitle>
        <h4
          style={{
            color: "black",
            // fontSize: "3rem",
            lineHeight: "1",
            textAlign: "center",
          }}
        >
          Contact Form
        </h4>
      </DialogTitle>
      <DialogContent>
        <form onSubmit={(e) => handleSubmit(e)}>
          <Grid container style={{ width: "100%" }} spacing={2}>
            <Grid item xs={12}>
              <h5 >
                Please provide all information.
              </h5>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Name"
                style={{ width: "100%" }}
                name="name"
                value={enquiry.name}
                onChange={handleChange}
                required
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Contact Number"
                style={{ width: "100%" }}
                name="contact"
                value={enquiry.contact}
                onChange={handleChange}
                required
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Email Address"
                style={{ width: "100%" }}
                name="fromEmail"
                value={enquiry.fromEmail}
                onChange={handleChange}
                required
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Subject"
                style={{ width: "100%" }}
                name="subject"
                value={enquiry.subject}
                onChange={handleChange}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Your Message"
                multiline
                rows={4}
                style={{ width: "100%" }}
                name="content"
                value={enquiry.content}
                onChange={handleChange}
                required
              />
            </Grid>
          </Grid>
          <DialogActions>
            <Button variant="outlined" onClick={() => handleDialog(!open)}>
              Cancel
            </Button>

            <Button
              variant="contained"
              type="submit"
              className="submit-btn"
            >
              Submit
            </Button>
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  );
}
