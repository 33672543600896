import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import { url } from "../../constants";
import axios from "axios";
import { Button, Divider, Grid } from "@mui/material";
import { TabComponent } from "../../components/mui/tab.component";
import ItemDescriptionComponent from "../../components/itemdescriptions/itemdescriptions";
import SpecTableComponent from "../../components/specsTable/spectable.component";
import { EmailOutlined } from "@mui/icons-material";
import { ContactFormComponent } from "../root/contactform.component";
import { ImageGalleryComponent } from "../../components/imagegallery/imagegallery.component";
import { ImageGalleryDialogComponent } from "../../components/imagegallery/imagegallerydialog.component";
import { Helmet } from "react-helmet";
import { useSetHeaderContext } from "../root/header/header.context";

export function ItemComponent() {
  const setHeader = useSetHeaderContext();
  const { itemName, productName } = useParams();
  const [item, setItem] = useState();
  const [tab, setTab] = useState("0");
  const [tabs, setTabs] = useState([]);
  const [contactDialog, setContactDialog] = useState(false);
  const [snackbar, setSnackbar] = useState(false);
  const [imageDialog, setImageDialog] = useState(false);

  useEffect(() => {
    axios
      .get(
        `${url}/api/v1/cms/products/${productName}/items/${itemName}?q=dir_name`
      )
      .then((response) => {
        const item = response.data;
        setItem(item);
        setHeader({ title: item.name });
        JSON.parse(item.descriptions).length > 0 &&
          setTabs((prevState) => [
            ...prevState,
            {
              label: "Details",
              component: (
                <ItemDescriptionComponent
                  descriptions={JSON.parse(item.descriptions)}
                />
              ),
            },
          ]);
        JSON.parse(item.specifications).length > 0 &&
          setTabs((prevState) => [
            ...prevState,
            {
              label: "Specifications",
              component: (
                <SpecTableComponent
                  specifications={JSON.parse(item.specifications)}
                />
              ),
            },
          ]);
      });
  }, []);

  return item ? (
    <div style={{ marginBottom: "5rem" }}>
      <Helmet>
        <title>{`${item.product_name} - ${item.name}`}</title>
        <meta name="description" content={item.brief_description} />
      </Helmet>
      <Grid
        container
        style={{
          width:
            window.visualViewport.width < 1080
              ? "100vw"
              : window.visualViewport.width < 1600
              ? "85vw"
              : "75vw",
          margin: "auto",
          justifyContent: "center",
          padding: "1rem",
          // alignItems: "center",
        }}
        spacing={2}
      >
        <Grid
          item
          container
          xs={12}
          md={6}
          height={"100%"}
          style={{
            border: "solid 1px rgb(0,0,0,0.2)",
            borderRadius: "1rem",
            padding: "2rem",
          }}
          rowGap={4}
          id="image"
        >
          <ImageGalleryComponent
            setImageDialog={setImageDialog}
            images={
              item.additional_image
                ? [item.image, ...JSON.parse(item.additional_image)]
                : [item.image]
            }
          />
        </Grid>

        <Grid
          item
          container
          xs={12}
          md={6}
          height={"100%"}
          rowGap={2}
          id="content"
        >
          <Grid item xs={12} display={"flex"} alignItems={"center"}>
            <h3 style={{ margin: 0, flex: 1 }}>{item.name}</h3>
            <Button
              variant="contained"
              className="submit-btn"
              onClick={() => setContactDialog(true)}
            >
              <EmailOutlined />
              Enquire Us
            </Button>
          </Grid>
          <Grid item xs={12}>
            <p>{item.brief_description}</p>
          </Grid>
          <Grid item xs={12}>
            <Divider sx={{ opacity: "0.5", height: "0.1px" }} />
          </Grid>
          <Grid item xs={12}>
            <TabComponent tab={tab} setTab={setTab} tabs={tabs} />
          </Grid>
        </Grid>
      </Grid>
      {contactDialog && (
        <ContactFormComponent
          open={contactDialog}
          subject={`Enquiry for ${productName}/${itemName}`}
          handleDialog={setContactDialog}
          setSnackbar={setSnackbar}
        />
      )}
      {imageDialog && (
        <ImageGalleryDialogComponent
          setImageDialog={setImageDialog}
          open={imageDialog}
          images={
            item.additional_image
              ? [item.image, ...JSON.parse(item.additional_image)]
              : [item.image]
          }
        />
      )}
    </div>
  ) : (
    <></>
  );
}
