import { useState } from "react";
import { Link } from "react-router-dom";

export function SolutionThmbnailComponent({ solution }) {
  const [overlay, setOverlay] = useState(false);

  return (
    <div
      className="img-wrapper  solution-gallery-wrapper"
      style={{
        width: "100%",
        position: "relative",
        height: "100%",
        padding: 0,
        margin: 0,
      }}
      onMouseEnter={() => setOverlay(true)}
      onMouseLeave={() => setOverlay(false)}
    >
      <Link to={`/solutions/${solution.dir_name}`} state={solution}>
        <div className="img-hover-wrapper " style={{ height: "100%" }}>
          <img
            src={solution.image ?? "/assets/placeholder.png"}
            alt={solution.name}
            title={solution.name}
            style={{
              height: "100%",
              // objectFit: "fill",
              width: "100%",
              borderRadius: 0,
            }}
            className="image"
          />
          <div className="hover-desc-wrapper" style={{ alignItems: "center" }}>
            <h1 style={{ fontSize: "1.5rem", color: "white", width: "100%" }}>
              {solution.name}
            </h1>
            <p
              style={{
                color: "white",
                padding: "1rem",
                maxHeight: "60%",
                overflow: "auto",
              }}
            >
              {solution.brief_description}
            </p>
            <button className="hover-btn" style={{ width: "50%" }}>
              View More
            </button>
          </div>
        </div>
        {!overlay && (
          <div
            className="txt-wrapper"
            style={{
              height: "min-content",
              bottom: 0,
              width: "100%",
              position: "absolute",
              background: `linear-gradient(90.13deg, #272c49 40%, 
        rgba(39, 44, 73, 0) 107.37%)`,
              // backgroundColor: "skyblue",
            }}
          >
            <div className="solution-gallery-title" style={{ zIndex: 0 }}>
              <span style={{ color: "whitesmoke", textAlign: "center" }}>
                {solution.name}
              </span>
            </div>
          </div>
        )}
      </Link>
    </div>
  );
}
