import React, { useEffect } from "react";
import AboutDesc from "./AboutDesc";
import AboutPartners from "./AboutPartners";
import { useSetHeaderContext } from "../root/header/header.context";

const About = () => {
  const setHeader = useSetHeaderContext();
  useEffect(() => {
    setHeader({
      bannerImage: "/assets/header-about.jpg",
      title: "About Us",
    });
    document.title = "About Us";
  }, []);

  return (
    <div>
      <AboutDesc />
      <AboutPartners />
    </div>
  );
};

export default About;
