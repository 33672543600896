import React, { useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import "./navbar.css";
import { Button, Divider, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";

const menuList = [
  { to: "/", name: "Home" },
  { to: "/solutions", name: "Solutions" },
  { to: "/products", name: "Products" },
  { to: "/services", name: "Services" },
  { to: "/about-us", name: "About Us" },
];
const useStyles = makeStyles({
  button: {
    color: "black",
    // fontFamily: "SF UI Text,outfit,sans-serif",
    borderRadius: "unset",
    "&:hover": {
      backgroundColor: "#28a5df",
      color: "white",
    },
  },
});

export function BrowserNavbarComponent() {
  const location = useLocation();
  const [click, setClick] = useState(false);
  const [currentPage, setCurrentPage] = useState("/");
  const classes = useStyles();

  const handleClick = () => setClick(!click);

  return (
    <div
      style={{
        position: "absolute",
        top: "0",
        width: "100%",
        backgroundColor: "white",
        zIndex: "20",
      }}
    >
      <div
        style={{
          width: "100%",
          backgroundColor: "#068bc9",
        }}
      >
        <Grid
          container
          columnGap={2}
          style={{ width: "60vw", margin: "auto", padding: ".5rem" }}
        >
          <Grid item>
            <div>
              <a
                href="mailto:sales@oxen.com.sg"
                style={{
                  textDecoration: "none",
                  color: "white",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <i
                  className="fas fa-envelope"
                  style={{ margin: 0, color: "white" }}
                />
                <span>Sales@oxen.com.sg</span>
              </a>
            </div>
          </Grid>
          <Grid item>
            <Divider
              sx={{ opacity: "0.5", height: "0.1px" }}
              orientation="vertical"
            />
          </Grid>
          <Grid item>
            <div
              style={{ display: "flex", alignItems: "center", color: "white" }}
            >
              <i
                className="fas fa-phone-alt"
                style={{ margin: 0, color: "white" }}
              />
              <span>+65 6748 0468</span>
            </div>
          </Grid>
        </Grid>
      </div>
      {/* <Divider sx={{ opacity: "0.5", height: "0.1px" }} /> */}
      <div style={{ width: "60vw", margin: "auto" }}>
        <nav className="navbar nav-container">
          <NavLink
            exact="true"
            to="/"
            className="nav-logo"
            style={{ margin: 0, flex: 1, justifyContent: "center" }}
          >
            <img
              src="/assets/website_logo_transparent_background.png"
              alt="logo"
              className="logo"
            />
          </NavLink>
          {menuList.map((menu) => {
            return (
              <NavLink
                exact="true"
                to={menu.to}
                activeclassname="active"
                onClick={() => setCurrentPage(menu.to)}
                style={{ height: "100%", width: "100%", flex: 1 }}
              >
                <Button
                  className={`${classes.button}`}
                  variant="text"
                  style={{
                    backgroundColor:
                      menu.to != "/"
                        ? location.pathname.includes(menu.to) && "#28a5df"
                        : menu.to == location.pathname && "#28a5df",
                    color:
                      menu.to != "/"
                        ? location.pathname.includes(menu.to) && "white"
                        : menu.to == location.pathname && "white",
                    fontStyle: currentPage == menu.to && "olique",
                    height: "100%",
                    width: "100%",
                  }}
                >
                  {menu.name}
                </Button>
              </NavLink>
            );
          })}
        </nav>
      </div>
    </div>
  );
}
