import { Button, Grid } from "@mui/material";
import { FiltersComponent } from "./filters.component";
import { useEffect, useReducer, useState } from "react";
import { filterHelperV2 } from "./products.service";
import {
  useFilterContext,
  useResetFiltersContext,
  useSetFilterContext,
} from "./filters.context";
import { useCloseDrawerDispatch } from "../root/drawer/drawer.context";

export function MobileFilterComponent({
  filters,
  products,
  handleFilterChange,
  resultCount
}) {
  const closeDrawer = useCloseDrawerDispatch();

  const setCheckedFilters = useSetFilterContext();
  const checkedFilters = useFilterContext();
  const resetFilters = useResetFiltersContext();

  function handleFilter(checked, value, key) {
    setCheckedFilters(value, key);
  }

  useEffect(() => {
    handleFilterChange();
  }, [checkedFilters]);
  return (
    <>
      <Grid container style={{ padding: "1rem", overflow: "auto" }} rowGap={2}>
        <FiltersComponent
          filters={filters}
          checkedFilters={checkedFilters}
          expandable={false}
          handleFilter={handleFilter}
        />
      </Grid>
      <Grid
        item
        container
        xs={12}
        columnSpacing={2}
        style={{
          position: "absolute",
          bottom: "0px",
          background: "white",
          padding: "1rem",
        }}
        // columnGap={2}
      >
        <Grid item xs={6} container justifyContent={"center"}>
          <Button
            variant="outlined"
            style={{ width: "100%" }}
            onClick={() => {
              resetFilters();
            }}
          >
            Clear Filters
          </Button>
        </Grid>
        <Grid item xs={6} container justifyContent={"center"}>
          <Button
            variant="contained"
            className="submit-btn"
            style={{ width: "100%" }}
            onClick={() => closeDrawer()}
          >
            {/* {`${
              resultCount > 0
                ? resultCount
                : products.flatMap((product) => product.productItems).length
            } Results`} */}

            Show Results
          </Button>
        </Grid>
      </Grid>
    </>
  );
}
