import React from "react";
import "./header.style.css";

export const HeaderComponent = ({
  bannerImage,
  title,
  descriptionTitle,
  description,
  button,
  titleSize = "lg",
}) => {
  return (
    <>
      <div
        className="subheader-wrapper flex-col"
        style={{
          height: "500px",
          width: "100%",
          backgroundImage: `${
            title || descriptionTitle || description
              ? `linear-gradient(90.13deg, #272c49 40%, 
        rgba(39, 44, 73, 0) 107.37%), url(${
          bannerImage ?? "/assets/cover1.jpeg"
        })`
              : `
        url(${bannerImage ?? "/assets/cover1.jpeg"})
        `
          }`,
          paddingLeft: "0",
          marginBottom: "1rem",
          backgroundSize: "100% 100%",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div
          style={{
            width: window.visualViewport.width < 1080 ? "100%" : "75%",
            maxHeight: "75%",
            padding: "1rem",
            margin: "auto",
            display: "flex",
            boxSizing: "border-box",
            flexDirection: "column",
            justifyContent: "center",
            // alignItems: "center",
          }}
        >
          {titleSize == "lg" ? <h2>{title}</h2> : <h3>{title}</h3>}
          <h4 style={{ color: "white", lineHeight: "1" }}>
            {descriptionTitle}
          </h4>
          <p
            style={{
              color: "white",
              fontSize: "1.2rem",
              width: window.visualViewport.width < 1080 ? "100%" : "50%",
              height: "100%",
              overflow: "auto",
              scrollbarWidth: "none",
            }}
          >
            {description}
          </p>
          {button}
        </div>
      </div>
    </>
  );
};
