import React, { useEffect } from "react";
import { Divider, Drawer, IconButton, Toolbar } from "@mui/material";
import { CloseOutlined } from "@mui/icons-material";
import { useCloseDrawerDispatch, useDrawerContext } from "./drawer.context";

export function DrawerComponent() {
  const drawerState = useDrawerContext();
  const closeDrawer = useCloseDrawerDispatch();

  useEffect(() => {
  }, [drawerState]);

  return (
    <React.Fragment key="webDrawer">
      <Drawer
        open={drawerState.open}
        onClose={() => closeDrawer()}
        anchor="right"
        sx={{
          width: drawerState.width,
          flexShrink: 0,
          top: drawerState.fullHeight ? "0px" : "80px",
          [`& .MuiDrawer-paper`]: {
            width: drawerState.width,
            boxSizing: "border-box",
            // height: "200vh",
          },
          "& .MuiBackdrop-root": {
            top: drawerState.fullHeight ? "0px" : "80px",
          },
          "& .MuiPaper-root": {
            zIndex: "0",
            top: drawerState.fullHeight ? "0px" : "80px",
          },
          "& .MuiToolbar-root": {
            minHeight: "0",
            height: "0",
          },
          "& .MuiBox-root": {
            // height: "100vh",
          },
        }}
      >
        <Toolbar />
        {drawerState.title && (
          <>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {drawerState.title}
              <IconButton
                style={{ padding: "1rem" }}
                onClick={() => closeDrawer()}
              >
                <CloseOutlined />
              </IconButton>
            </div>
            <Divider />
          </>
        )}
        {drawerState.content}
      </Drawer>
    </React.Fragment>
  );
}
