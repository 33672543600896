export function CheckedFiltersReducer(state, action) {
  switch (action.type) {
    case "setFilter":
      if (state[action.key].includes(action.payload)) {
        return {
          ...state,
          [action.key]: state[action.key].filter(
            (filter) => filter != action.payload
          ),
        };
      }
      return {
        ...state,
        [action.key]: [...state[action.key], action.payload],
      };

    case "setSearch":
      return { ...state, search: action.payload };

    case "hydrateFilters":
      return { ...checkedFiltersState, ...action.payload };

    case "resetFilters": {
      return { ...state, product: [], brand: [], filter: [] };
    }

    case "resetAll": {
      return checkedFiltersState;
    }
  }
}

export const checkedFiltersState = {
  product: [],
  brand: [],
  filter: [],
  search: "",
};
