import React from "react";
import { Link } from "react-router-dom";
import { DotDecoComponent } from "../../components/dotdeco/dotdeco.component";

const HomeRepair = () => {
  return (
    <div className="repair-wrapper flex-center flex-col">
      <div className="blue-divider" />
      <h2>Repair and Calibration</h2>
      <p>
        We believe in supplying only high quality, reliable products. you simply
        need advice on operating it, our dedicated team of staff will be ready
        to assist you.
      </p>
      <DotDecoComponent />

      <Link to="/services">
        <button className="learn-more">Technical Support</button>
      </Link>
    </div>
  );
};

export default HomeRepair;
