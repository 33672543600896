import { Divider, Grid } from "@mui/material";
import React from "react";

const ItemDescriptionComponent = ({ descriptions }) => {
  return (
    <Grid container style={{ width: "100%" }} spacing={2}>
      {descriptions?.map((description) => {
        return (
          <Grid item xs={12}>
            <h5 style={{ textAlign: "right" }}>{description.title}</h5>
            <Divider sx={{ opacity: "0.5",height:'0.1px' }} />
            <p>{description.description}</p>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default ItemDescriptionComponent;
