import { ArrowForwardIosRounded } from "@mui/icons-material";
import { Divider, Grid } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import "./item.style.css";
export function ImageGalleryComponent({ images, imageDialog, setImageDialog }) {
  const imageIndexRef = useRef(0);
  const [imageIndex, setImageIndex] = useState(0);
  //   useEffect(() => {
  //     const interval = setInterval(() => {
  //       nextImage();
  //     }, 5000);

  //     return () => clearInterval(interval);
  //   }, [images]);

  const nextImage = () => {
    if (imageIndexRef.current < images.length - 1) {
      setImageIndex((imageIndexRef.current += 1));
    } else if (imageIndexRef.current === images.length - 1) {
      imageIndexRef.current = 0;
      setImageIndex(0);
    }
  };

  const prevImage = () => {
    if (imageIndexRef.current > 0) {
      setImageIndex((imageIndexRef.current -= 1));
    } else if (imageIndexRef.current === 0) {
      imageIndexRef.current = images.length - 1;
      setImageIndex(images.length - 1);
    }
  };

  const moveDot = (index) => {
    imageIndexRef.current(index);
  };

  return (
    <>
      <Grid item xs={12}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: window.visualViewport.width < 1080 ? "200px" : "500px",
            position: "relative",
          }}
        >
          {images.map((image, index) => (
            <img
              src={images[imageIndex]}
              className={
                index == imageIndex ? "current-item-image" : "item-image"
              }
            />
          ))}
        </div>
      </Grid>
      <Grid item xs={12}>
        <Divider sx={{ opacity: "0.5", height: "0.1px" }} />
      </Grid>
      <Grid item container xs={12} spacing={2}>
        {images.map((image, index) => {
          return (
            index < 4 &&
            (index == 3 && images.length > 4 ? (
              <Grid
                item
                xs={3}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  position: "relative",
                }}
              >
                <img
                  src={image}
                  style={{ opacity: ".3", maxWidth: "100%", maxHeight: "100%" }}
                />
                <div
                  style={{
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    cursor: "pointer",
                  }}
                  onClick={() => setImageDialog(true)}
                >
                  <span>View {`${images.length - (index + 1)}+ images`}</span>
                  <ArrowForwardIosRounded />
                </div>
              </Grid>
            ) : (
              <Grid
                item
                xs={3}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  position: "relative",
                  padding: ".5rem",
                  border: `${
                    index == imageIndex
                      ? "solid .25rem rgb(0,200,200,0.2)"
                      : "none"
                  }`,
                  borderRadius: ".5rem",
                  cursor: "pointer",
                }}
                onClick={() => setImageIndex(index)}
              >
                <img
                  src={image}
                  style={{
                    maxWidth: "100%",
                    maxHeight: "100%",
                    padding: "1rem",
                  }}
                />
              </Grid>
            ))
          );
        })}
      </Grid>
    </>
  );
}
