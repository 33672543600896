import { SearchOutlined } from "@mui/icons-material";
import { Grid } from "@mui/material";

export function NoProductComponent() {
  return (
    <Grid
      item
      container
      xs={12}
      style={{
        width: "100%",
        minHeight: "40vh",
        backgroundColor: "aliceblue",
        textAlign: "center",
        alignItems: "center",
        justifyContent: "center",
        color: "darkgray",
      }}
    >
      <Grid item container xs={12} style={{ height: "min-content" }}>
        <Grid item xs={12}>
          <SearchOutlined />
        </Grid>
        <Grid item xs={12}>
          <span>
            No products found. Please update the filters and try again.
          </span>
        </Grid>
      </Grid>
    </Grid>
  );
}
