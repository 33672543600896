import { Divider, Grid } from "@mui/material";
import { FilterComponent } from "./filter.component";
import { useEffect } from "react";

export function FiltersComponent({
  filters,
  expandable = true,
  checkedFilters,
  handleFilter
}) {

  useEffect(()=>{
  },[checkedFilters])

  return filters.map((filter) => {
    return (
      <>
        <Grid item xs={12}>
          <FilterComponent
            items={filter.items}
            filterKey={filter.filterKey}
            itemKey={filter.itemKey}
            title={filter.title}
            handleFilter={handleFilter}
            expandable={expandable}
            checkedFilters={checkedFilters}
            key={filter.title}
          />
        </Grid>
        <Grid item xs={12}>
          <Divider sx={{ opacity: "0.5", height: "0.1px" }} />
        </Grid>
      </>
    );
  });
}
