import {
  Button,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import ProductHeader from "../productheader";
import { Link, useLocation, useParams } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import axios from "axios";
import { url } from "../../constants";
import { ProductItemThumbnailComponent } from "./productitemthumbnail.component";
import { FilterList, SearchOutlined } from "@mui/icons-material";
import { FilterComponent } from "./filter.component";
import { filterHelper, filterHelperV2 } from "./products.service";
import { NoProductComponent } from "./noproduct.component";
import _ from "lodash";
import { Helmet } from "react-helmet";
import { useSetHeaderContext } from "../root/header/header.context";
import {
  useFilterContext,
  useResetAllContext,
  useSetFilterContext,
  useSetSearchContext,
} from "./filters.context";
import { FiltersComponent } from "./filters.component";
import { MobileFilterComponent } from "./mobilefilter.component";
import { useOpenDrawerDispatch } from "../root/drawer/drawer.context";

export function ProductComponent({}) {
  const setHeader = useSetHeaderContext();
  const [product, setProduct] = useState();
  const { productName } = useParams();
  const [viewProduct, setViewProduct] = useState();
  const [brands, setBrands] = useState([]);
  const [filters, setFilters] = useState([]);
  const [noProductFound, setNoProductFound] = useState(false);
  const [resultCount, setResultCount] = useState(0);

  const searchDebounce = useCallback(
    _.debounce((value) => setSearch(value), 300),
    []
  );
  const openDrawer = useOpenDrawerDispatch();
  const setCheckedFilters = useSetFilterContext();
  const checkedFilters = useFilterContext();
  const setSearch = useSetSearchContext();
  const resetAllFilter = useResetAllContext();

  function handleFilter(checked, value, key) {
    setCheckedFilters(value, key);
  }

  function handleFilterChange() {
    setNoProductFound(false);
    const filteredProducts = filterHelperV2(checkedFilters, [product]);

    if (filteredProducts.length == 0) {
      if (
        checkedFilters.brand.length == 0 &&
        checkedFilters.filter.length == 0 &&
        checkedFilters.product.length == 0 &&
        checkedFilters.search.length == 0
      ) {
        setResultCount(0);
        setViewProduct(product);
      } else {
        setNoProductFound(true);
      }
    } else {
      setViewProduct(filteredProducts[0]);
      setResultCount(
        filteredProducts.flatMap((product) => product.productItems).length
      );
    }
  }

  useEffect(() => {
    if (product) {
      handleFilterChange();
    }
  }, [checkedFilters, product]);

  const filterComponents = [
    {
      items: brands,
      filterKey: "brand",
      title: "Brands",
    },
    {
      items: filters,
      filterKey: "filter",
      title: "Filters",
    },
  ];
  useEffect(() => {
    resetAllFilter();
    axios
      .get(`${url}/api/v1/cms/products/${productName}?q=dir_name`)
      .then((response) => {
        const product = response.data;
        setProduct(product);
        setViewProduct(product);
        setHeader({
          bannerImage: product.banner_image,
          description: product.description,
          descriptionTitle: product.description_title,
          title: product.name,
        });
      });

    axios
      .get(`${url}/api/v1/cms/products/${productName}/brands`)
      .then((response) => setBrands(response.data));

    axios
      .get(`${url}/api/v1/cms/products/${productName}/filters`)
      .then((response) => setFilters(response.data));
  }, []);

  return product ? (
    <div style={{ marginBottom: "5rem" }}>
      <Helmet>
        <title>{product.name}</title>
        <meta name="description" content={product.description} />
      </Helmet>
      <Grid
        container
        style={{
          // flexDirection: "column",
          width:
            window.visualViewport.width < 1080
              ? "100vw"
              : window.visualViewport.width < 1600
              ? "85vw"
              : "75vw",
          // alignItems: "center",
          margin: "auto",
        }}
        spacing={2}
      >
        {window.visualViewport.width > 1200 ? (
          <Grid
            item
            xs={3}
            container
            style={{
              height: "min-content",
              padding: "1rem",
              borderRadius: "1rem",
            }}
          >
            <TextField
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchOutlined />
                  </InputAdornment>
                ),
              }}
              onChange={(e) => searchDebounce(e.target.value)}
              placeholder="Search"
              size="small"
            />
            <FiltersComponent
              filters={filterComponents}
              checkedFilters={checkedFilters}
              handleFilter={handleFilter}
            />
          </Grid>
        ) : (
          <Grid item container xs={12} alignItems={"center"}>
            <Grid item xs={10}>
              <TextField
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchOutlined />
                    </InputAdornment>
                  ),
                }}
                placeholder="Search"
                size="small"
                onChange={(e) => searchDebounce(e.target.value)}
              />
            </Grid>
            <Grid item xs={2} display={"flex"} justifyContent={"center"}>
              <IconButton
                onClick={() =>
                  openDrawer({
                    title: (
                      <div style={{ flex: "1", display: "flex" }}>
                        <h5
                          style={{
                            padding: "1rem",
                            flex: "1",
                          }}
                        >
                          Filters
                        </h5>
                      </div>
                    ),
                    content: (
                      <MobileFilterComponent
                        filters={filterComponents}
                        products={[product]}
                        handleFilterChange={handleFilterChange}
                        resultCount={resultCount}
                      />
                    ),
                    fullHeight: true,
                    width: "100vw",
                  })
                }
              >
                <FilterList />
              </IconButton>
            </Grid>
          </Grid>
        )}
        <Grid
          item
          xs={12}
          md={9}
          container
          style={{ maxWidth: "100%", width: "100%", height: "100%" }}
          spacing={2}
        >
          {noProductFound ? (
            <NoProductComponent />
          ) : (
            viewProduct?.productItems.map((item) => {
              return (
                <>
                  <ProductItemThumbnailComponent
                    link={`/products/${product.dir_name}/${item.dir_name}`}
                    image={item.image}
                    state={item}
                    title={item.name}
                    product={product}
                    productItem={item}
                  />
                </>
              );
            })
          )}
        </Grid>
      </Grid>
    </div>
  ) : (
    <></>
  );
}
